import React, { useRef, useEffect, useState } from "react";
import ReactMapGL from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import * as MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { Row, Col } from "react-bootstrap";
import { Icon } from "semantic-ui-react";
import { Marker, Popup, FlyToInterpolator, Layer, Source } from "react-map-gl";
import data from "../constants/menu";
import { ConsoleSqlOutlined } from "@ant-design/icons";
/* eslint import/no-webpack-loader-syntax: off */

import mapboxgl from "!mapbox-gl";

//localhost ///production // ... (other imports and code) // ... (previous imports and code)

mapboxgl.accessToken =
  "pk.eyJ1IjoiYmFjay1vZmZpY2UtbWFuYWdlbWVudCIsImEiOiJjbGZxcmJlbjgwMWJxNDRwYjNpdXp0cGJ6In0.nfzF7xWo_S0Q90LhXBurxw";

export default function GenerateMap(props) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(114.1838);
  const [lat, setLat] = useState(22.2797);
  const [zoom, setZoom] = useState(5.5);
  const [mapData, setMapData] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [hasMarker, setHasMarker] = useState(false); // Track if a marker has been added

  const nextMarkerId = useRef(0);

  const copyToClipboard = (latitude, longitude) => {
    const textToCopy = `${latitude}, ${longitude}`;
    const textField = document.createElement("textarea");
    textField.innerText = textToCopy;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();

    // You can show a notification or perform any other action after copying to clipboard
    alert("Latitude and Longitude copied to clipboard!");
  };

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/light-v10",
      center: [lng, lat],
      zoom: zoom,
    });
    map.current.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        reverseGeocode: true,
      })
    );
  }, [lng, lat, zoom]);

  useEffect(() => {
    if (!map.current) return;
    map.current.on("load", () => {
      // Insert the layer beneath any symbol layer.
      const layers = map.current.getStyle().layers;
      const labelLayerId = layers.find(
        (layer) => layer.type === "symbol" && layer.layout["text-field"]
      ).id;

      // The 'building' layer in the Mapbox Streets
      // vector tileset contains building height data
      // from OpenStreetMap.
      map.current.addLayer(
        {
          id: "add-3d-buildings",
          source: "composite",
          "source-layer": "building",
          filter: ["==", "extrude", "true"],
          type: "fill-extrusion",
          minzoom: 15,
          paint: {
            "fill-extrusion-color": "#aaa",
            "fill-extrusion-height": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              15.05,
              ["get", "height"],
            ],
            "fill-extrusion-base": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              15.05,
              ["get", "min_height"],
            ],
            "fill-extrusion-opacity": 0.6,
          },
        },
        labelLayerId
      );
    });
    // wait for map to initialize
  }, []);

  useEffect(() => {
    if (!map.current) return;

    const handleClick = (e) => {
      if (hasMarker) return; // Prevent additional markers if one is already added

      const id = nextMarkerId.current++;
      const color = getRandomColor(); // Generate a random color for the marker
      const marker = new mapboxgl.Marker({ color: color })
        .setLngLat([e.lngLat.lng, e.lngLat.lat])
        .addTo(map.current);

      setMapData((prevMapData) => [
        ...prevMapData,
        {
          id: id,
          marker: marker,
          color: color, // Save the color in the mapData
          name: null,
          latitude: e.lngLat.lat,
          longitude: e.lngLat.lng,
        },
      ]);

      fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${e.lngLat.lng},${e.lngLat.lat}.json?access_token=pk.eyJ1IjoibGt0eWFnaSIsImEiOiJja2dhMmVrNGcwMzNxMnRrenhrbGh4YzBoIn0.h31ayIFQsUoW1YpL3LVkOQ`
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          let address = data.features[0].place_name.split(",")[0];
          setMapData((prevMapData) =>
            prevMapData.map((item) =>
              item.id === id
                ? {
                    ...item,
                    name: address,
                  }
                : item
            )
          );
        });

      setHasMarker(true); // Set the flag to true after adding the first marker
      map.current.off("click", handleClick); // Remove the click event listener
    };

    map.current.on("click", handleClick);

    return () => {
      if (map.current) {
        map.current.off("click", handleClick); // Clean up the event listener on component unmount
      }
    };
  }, [hasMarker]);

  const getRandomColor = () => {
    // Function to generate a random hex color
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
  };

  const handleDeleteMapData = (id) => {
    const mapDataToDelete = mapData.find((item) => item.id === id);

    if (mapDataToDelete) {
      mapDataToDelete.marker.remove();
      setMapData((prevMapData) => prevMapData.filter((item) => item.id !== id));
      setHasMarker(false); // Allow adding a new marker after deletion
    }
  };

  useEffect(() => {
    // Log mapData to the console
    const transformedMapData = mapData.map(
      ({ name, latitude, longitude, color }) => ({
        name,
        latitude,
        longitude,
        color,
      })
    );
    // Call the callback function with the transformed mapData
    props.onMapDataChange(transformedMapData);
  }, [mapData]);

  console.log("data", mapData);

  return (
    <Row>
      <Col md={7} sm={12} xs={12} className="mapcol">
        <p className="toppara">Mark locations to analyze</p>
        <div ref={mapContainer} className="location-map-container" />
      </Col>
      <Col lg={5} className="asset">
        <div className="table">
          <div className="table__body">
            <div className="table__row table__heading">
              <div className="table__cell">Latitude</div>
              <div className="table__cell">Longitude</div>
              <div className="table__cell">Delete</div>
              <div className="table__cell">Copy</div>
            </div>
            {mapData.map((item, index) => (
              <div className="table__row" key={item.id}>
                <div className="table__cell" style={{ color: "black" }}>
                  <Icon
                    name="map marker alternate"
                    style={{ color: item.color }}
                  />
                  {item.latitude.toFixed(3)}
                </div>
                <div className="table__cell" style={{ color: "black" }}>
                  {item.longitude.toFixed(3)}
                </div>
                <div className="table__cell">
                  <Icon
                    name="trash alternate"
                    onClick={() => handleDeleteMapData(item.id)}
                    style={{
                      cursor: "pointer",
                      color: "black",
                      paddingLeft: "10%",
                    }}
                  ></Icon>
                </div>
                <div className="table__cell">
                  <Icon
                    name="copy"
                    onClick={() =>
                      copyToClipboard(item.latitude, item.longitude)
                    }
                    style={{ cursor: "pointer", color: "black" }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Col>
    </Row>
  );
}
