import React, { Component, createRef } from "react";
import { Table, Switch, Radio, Row, Col, Button, Checkbox, Select } from "antd";
import { Modal, Image } from "semantic-ui-react";
import { connect } from "react-redux";
import { locus } from "../actions";
import { getBoundsOfDistance } from "geolib";
import html2canvas from "html2canvas";
import maplogo from "../assets/map_logo.png";

import "./intensel_map.css";
/* eslint import/no-webpack-loader-syntax: off */

import mapboxgl from "!mapbox-gl";
import { tickStep, timeThursdays } from "d3";

mapboxgl.accessToken =
  "pk.eyJ1IjoiYmFjay1vZmZpY2UtbWFuYWdlbWVudCIsImEiOiJjbGZxcmJlbjgwMWJxNDRwYjNpdXp0cGJ6In0.nfzF7xWo_S0Q90LhXBurxw";
//"pk.eyJ1IjoibGt0eWFnaSIsImEiOiJja2dhMmVrNGcwMzNxMnRrenhrbGh4YzBoIn0.h31ayIFQsUoW1YpL3LVkOQ";

class LegendControl {
  onAdd(map) {
    this._map = map;
    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl legend";
    const paragraph = document.createElement("p");
    paragraph.textContent = "Flood in meters";
    paragraph.className = "legend-title"; // Optional: Add a class for custom styling
    this._container.appendChild(paragraph);
    const legendItems = [
      { color: "#33DDFF", label: "0-0.25" },

      { color: "#33C2FF", label: "0.25-0.5" },
      { color: "#78FFC9", label: "0.5-0.75" },
      { color: "#E0FF55", label: "0.75-1" },
      { color: "#FFD600", label: "1-1.5" },
      { color: "#FF8700", label: "1.5-2" },
      { color: "#FF2F00", label: ">2" },
    ];

    legendItems.forEach((item) => {
      const legendItem = document.createElement("div");
      legendItem.className = "legend-item";

      const colorBox = document.createElement("span");
      colorBox.className = "legend-color";
      colorBox.style.backgroundColor = item.color;

      const label = document.createElement("span");
      label.className = "legend-label";
      label.textContent = item.label;

      legendItem.appendChild(colorBox);
      legendItem.appendChild(label);
      this._container.appendChild(legendItem);
    });

    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}
class DownloadMap extends Component {
  constructor(props) {
    super(props);
    this.mapContainer = React.createRef();

    this.state = {
      dataSource: [
        {
          key: "1",
          name: "City A",
          country_code: "CHN",
          rainfall_flood: 0.687032,
          gdp: 44.6213,
          populationDensity: 2788.9462,
          rainfall_flood_max: 2.72103,
          rainfall_flood_min: 0.30654,
        },
      ], // Initialized to an empty array

      modalVisible: false,
      options: "",
      duration_data: "",
      filterModalVisible: false,
      downloadModalVisible: false,
      filterType: "",
      selectedCity: null,
      mapStyle: "mapbox://styles/mapbox/satellite-v9",
      name: "",
      distance: "",
      scenario: "85",
      year: "2050",
      columns: [],
      return_period: "100",
      scenario_download: ["85"],
      year_download: ["2050"],
      latitude: "",
      longitude: "",
      yearOptions: [],
      rcpOptions: [],
      scale: "",
      is3DViewEnabled: false,
      floodDepth: null,
      isMapLoaded: false, // Track map loading status
      district: "", // New state for district name

      mapValueResponse: {
        hand: null,
        elevation: null,
        no_of_days: null,
        slope: null,
        flood: null,
        value: null,
      },
      variable: "",
      showTable: true, // Flag to control table visibility
      mapBounds: null, // State variable to hold map bounds
    };
    this.rasterLayerAdded = false;

    this.map = null;
    this.popup = null;
  }
  getDataForReturnPeriod = (
    data,
    returnPeriod,
    variable,
    scenario = null,
    year = null
  ) => {
    console.log("datasource getting data");

    // Initialize a result object with default values
    const result = {
      name: data.name,
      gdp: data.gdp,
      populationDensity: data.population,
      country_code: data.country_code,
      latitude: data.coordinates.latitude,
      longitude: data.coordinates.longitude,
    };

    if (variable === "rainfall_flood") {
      // Check if returnPeriod is available
      if (
        data.rainfall_flood &&
        data.rainfall_flood.average[returnPeriod] !== undefined
      ) {
        result.rainfall_flood_avg = data.rainfall_flood.average[returnPeriod];
        result.rainfall_flood_max = data.rainfall_flood.max[returnPeriod];
        result.rainfall_flood_min = data.rainfall_flood.min[returnPeriod];
      } else {
        console.warn("Invalid returnPeriod or missing rainfall_flood data.");
        result.rainfall_flood_avg = null;
        result.rainfall_flood_max = null;
        result.rainfall_flood_min = null;
      }
    } else if (variable === "storm_surge" && scenario && year) {
      const key = `${scenario}_${year}`;

      // Check if key is available in storm_surge data
      if (data.storm_surge && data.storm_surge.average[key] !== undefined) {
        result.storm_surge_avg = data.storm_surge.average[key];
        result.storm_surge_max = data.storm_surge.max[key];
        result.storm_surge_min = data.storm_surge.min[key];
      } else {
        console.warn("Invalid scenario/year or missing storm_surge data.");
        result.storm_surge_avg = null;
        result.storm_surge_max = null;
        result.storm_surge_min = null;
      }
    }

    return result;
  };
  updateScale = () => {
    if (!this.map) return;

    const bounds = this.map.getBounds();
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    // Calculate the distance between the northeast and southwest corners
    const distance = this.getDistance(ne.lng, ne.lat, sw.lng, sw.lat);

    // Update the state with the new scale
    this.setState({ scale: `${(distance / 1000).toFixed(1)} km` }); // Convert to kilometers
  };

  // Haversine formula to calculate the distance between two lat/lng points
  getDistance = (lng1, lat1, lng2, lat2) => {
    const R = 6371000; // Radius of the Earth in meters
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lng2 - lng1) * (Math.PI / 180);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Distance in meters
  };

  updateDataSource = (
    dataSource,
    apiResponse,
    returnPeriod = null,
    scenario = null,
    year = null
  ) => {
    console.log("Updating dataSource with:", {
      returnPeriod,
      scenario,
      year,
      dataSource,
    });

    const variable = apiResponse.portfolio_variables.includes("Storm Surge")
      ? "storm_surge"
      : "rainfall_flood";

    return dataSource.map((city) => {
      console.log("Getting data for city:", city.name);
      const newData = this.getDataForReturnPeriod(
        apiResponse,
        returnPeriod,
        variable,
        scenario,
        year
      );
      console.log("New data:", newData);
      return { ...city, ...newData };
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.name
    ) {
      let formdata = new FormData();
      formdata.append("name", this.props.location.state.name.name);

      this.setState({ name: this.props.location.state.name.name }, () => {
        this.props.generateMap(formdata);
      });
    }
    this.initializeMap();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.download_map_value !== this.props.download_map_value) {
      const mapvalue_response = this.props.download_map_value;
      this.setState({ mapValueResponse: mapvalue_response }, () => {
        this.showPopup(this.state.longitude, this.state.latitude);
      });
    }

    if (prevState.mapBounds !== this.state.mapBounds && this.map) {
      if (this.state.mapBounds && this.state.mapBounds.length === 2) {
        console.log("Map Bounds", this.state.mapBounds);
        const [[swLng, swLat], [neLng, neLat]] = this.state.mapBounds;
        if (!isNaN(swLng) && !isNaN(swLat) && !isNaN(neLng) && !isNaN(neLat)) {
          this.map.setMaxBounds(this.state.mapBounds);
        } else {
          console.error("Invalid map bounds: ", this.state.mapBounds);
        }
      } else {
        console.error(
          "Map bounds are not correctly formatted: ",
          this.state.mapBounds
        );
      }
    }
    if (prevProps.map_response !== this.props.map_response) {
      const { map_response } = this.props;

      const durationData = map_response.duration_data;

      const Options = durationData.map((item) => ({
        label: item,
        value: item,
      }));

      this.setState({ options: Options, duration_data: durationData[0] }, () =>
        console.log("options", this.state.options)
      );

      const distance = parseFloat(map_response.distance);
      if (!isNaN(distance)) {
        this.setState({ distance: map_response.distance }, () => {
          localStorage.setItem("distance", this.state.distance);
          this.handleMapBounds(map_response);
        });
      } else {
        console.error(
          "Invalid distance in map_response: ",
          map_response.distance
        );
      }

      const variable = map_response.portfolio_variables.includes("Storm Surge")
        ? "storm_surge"
        : "rainfall_flood";
      this.setState({ variable: variable });

      let updatedDataSource;

      if (variable === "rainfall_flood") {
        updatedDataSource = this.updateDataSource(
          this.state.dataSource,
          map_response,
          this.state.return_period,
          null,
          null
        );
      } else if (variable === "storm_surge") {
        console.log("setting datasource");
        updatedDataSource = this.updateDataSource(
          this.state.dataSource,
          map_response,
          null,
          this.state.scenario,
          this.state.year
        );
      }
      console.log("Updated dataSource:", this.state.dataSource);

      this.setState(
        {
          dataSource: updatedDataSource,
        },
        () => {
          this.updateTableColumns(variable);

          this.updateRasterLayer();
        }
      );

      if (
        prevState.scenario !== this.state.scenario ||
        prevState.year !== this.state.year ||
        prevState.return_period !== this.state.return_period
      ) {
        let updatedDataSource;
        if (variable === "rainfall_flood") {
          updatedDataSource = this.updateDataSource(
            this.state.dataSource,
            this.props.map_response,
            this.state.return_period
          );
        } else if (variable === "storm_surge") {
          console.log("datasorucccc");
          updatedDataSource = this.updateDataSource(
            this.state.dataSource,
            this.props.map_response,
            null,
            this.state.scenario,
            this.state.year
          );
        }
        this.setState(
          { dataSource: updatedDataSource },
          this.updateRasterLayer
        );
      }

      if (prevState.mapStyle !== this.state.mapStyle) {
        // Store current center and zoom
        const currentCenter = this.map.getCenter();
        const currentZoom = this.map.getZoom();

        // Update map style
        this.updateMapStyle();

        // Restore the map center and zoom after the style change
        this.map.once("styledata", () => {
          this.map.setCenter(currentCenter);
          this.map.setZoom(currentZoom);
        });
      }
    }
  }
  fetchDistrictName = async (latitude, longitude) => {
    const mapboxToken =
      "pk.eyJ1IjoibGt0eWFnaSIsImEiOiJja2dhMmVrNGcwMzNxMnRrenhrbGh4YzBoIn0.h31ayIFQsUoW1YpL3LVkOQ";
    const mapboxUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${mapboxToken}`;

    try {
      const response = await fetch(mapboxUrl);
      if (response.ok) {
        const data = await response.json();

        // Extract the first feature from the response, which typically has the most specific location information
        const fullPlaceName =
          data.features.length > 0
            ? data.features[0].place_name
            : "Unknown Location";
        const districtName = fullPlaceName.split(",")[0]; // Get the part before the first comma

        this.setState({ district: districtName });
      } else {
        console.error("Error fetching district name:", response.statusText);
        this.setState({ district: "Unknown Location" });
      }
    } catch (error) {
      console.error("Error fetching district name:", error);
      this.setState({ district: "Unknown Location" });
    }
  };

  handleMapBounds(map_response) {
    const { coordinates } = map_response;
    const { latitude, longitude } = coordinates || {};

    if (latitude && longitude && this.state.distance) {
      const distanceStr =
        this.state.distance || localStorage.getItem("distance");
      const distanceInMeters = parseInt(distanceStr) * 460;

      console.log(
        "Latitude:",
        latitude,
        "Longitude:",
        longitude,
        "Distance in meters:",
        distanceInMeters
      );

      const bounds = getBoundsOfDistance(
        { latitude, longitude },
        distanceInMeters
      );

      if (bounds && bounds.length === 2) {
        const mapBounds = [
          [bounds[0].longitude, bounds[0].latitude],
          [bounds[1].longitude, bounds[1].latitude],
        ];
        console.log("Calculated map bounds:", mapBounds);

        this.setState({ mapBounds, latitude, longitude }, () => {
          if (this.map) {
            console.log("jump map");
            this.map.jumpTo({
              center: [this.state.longitude, this.state.latitude],
              zoom: 15,
            });
            this.fetchDistrictName(latitude, longitude);
          }
        });
      } else {
        console.error("Invalid map bounds calculated:", bounds);
      }
    } else {
      console.error(
        "Invalid coordinates or distance:",
        coordinates,
        this.state.distance
      );
    }
  }

  componentWillUnmount() {
    if (this.map) {
      this.map.remove();
    }
  }

  initializeMap = () => {
    // Ensure that the container reference is valid
    if (this.mapContainer && this.mapContainer.current) {
      this.map = new mapboxgl.Map({
        container: this.mapContainer.current, // Use the valid reference here
        style: this.state.mapStyle,
        center: [this.state.longitude, this.state.latitude],
        zoom: 12,
        maxBounds: this.state.mapBounds,
        pitch: 0,
        bearing: 0,
        pitchWithRotate: false,
        dragRotate: false,
        touchZoomRotate: false,
      });

      this.map.addControl(new mapboxgl.FullscreenControl());
      this.map.on("load", () => {
        console.log("styledata event triggered");
        if (this.map.isStyleLoaded()) {
          console.log("Map style is fully loaded!");

          // Debugging logs to check function calls
          console.log("Calling addRasterLayer...");
          this.addRasterLayer();

          console.log("Calling addLegendControl...");
          this.addLegendControl();

          console.log("Calling addCenterMarker...");
          // this.addCenterMarker();

          // Set the map as loaded to show relevant UI elements
          console.log("Setting map as loaded...");
          this.setState({ isMapLoaded: true });
        }
      });

      this.map.on("click", this.handleMapHover);
      this.map.on("zoom", this.updateScale);
      this.map.on("moveend", this.updateScale);

      // Set the initial scale
      this.updateScale();
    } else {
      console.error("Map container reference is not defined");
    }
  };

  addCenterMarker = () => {
    console.log("marker", this.state.latitude);
    const centerCoordinates = [
      this.state.longitude || 0,
      this.state.latitude || 0,
    ];

    // Create a new marker and set its position to the map center
    this.centerMarker = new mapboxgl.Marker({ color: "black" }) // Optional: Customize marker color
      .setLngLat(centerCoordinates)
      .addTo(this.map);
  };
  add3DBuildingsLayer = () => {
    const layers = this.map.getStyle().layers;
    const labelLayerId = layers.find(
      (layer) => layer.type === "symbol" && layer.layout["text-field"]
    ).id;

    if (this.map.getLayer("add-3d-buildings")) {
      return; // If the layer already exists, do nothing
    }

    this.map.addLayer(
      {
        id: "add-3d-buildings",
        source: "composite",
        "source-layer": "building",
        filter: ["==", "extrude", "true"],
        type: "fill-extrusion",
        paint: {
          "fill-extrusion-color": "#ffffff",
          "fill-extrusion-height": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            15.05,
            ["get", "height"],
          ],
          "fill-extrusion-base": [
            "interpolate",
            ["linear"],
            ["zoom"],
            15,
            0,
            15.05,
            ["get", "min_height"],
          ],
          "fill-extrusion-opacity": 0.6,
        },
      },
      labelLayerId
    );
  };
  add2DBuildingsLayer = () => {
    const layers = this.map.getStyle().layers;
    const labelLayerId = layers.find(
      (layer) => layer.type === "symbol" && layer.layout["text-field"]
    ).id;

    if (this.map.getLayer("building-2d")) {
      return; // If the layer already exists, do nothing
    }

    this.map.addLayer(
      {
        id: "building-2d",
        source: "composite",
        "source-layer": "building",
        type: "fill",
        paint: {
          "fill-color": "#d6d6d6",
          "fill-opacity": 0.6,
        },
      },
      labelLayerId
    );
  };
  remove2DBuildingsLayer = () => {
    if (this.map.getLayer("building-2d")) {
      this.map.removeLayer("building-2d");
      this.map.removeSource("composite");
    }
  };

  remove3DBuildingsLayer = () => {
    if (this.map.getLayer("add-3d-buildings")) {
      this.map.removeLayer("add-3d-buildings");
      this.map.removeSource("composite");
    }
  };

  handle3DViewChange = (checked) => {
    this.setState({ is3DViewEnabled: checked }, () => {
      if (this.state.is3DViewEnabled) {
        this.add3DBuildingsLayer();
        this.remove2DBuildingsLayer();
      } else {
        this.remove3DBuildingsLayer();
        this.add2DBuildingsLayer();
      }
    });
  };

  updateMapStyle = () => {
    if (this.map) {
      // Store current center and zoom
      const currentCenter = this.map.getCenter();
      const currentZoom = this.map.getZoom();

      console.log("Updating map style to:", this.state.mapStyle);

      this.map.setStyle(this.state.mapStyle);
      this.map.once("styledata", () => {
        this.addRasterLayer();
        //this.addLegendControl();
        if (this.state.mapStyle.includes("dark")) {
          this.add2DBuildingsLayer();
        }
        // Restore the map center and zoom after the style change
        this.map.setCenter(currentCenter);
        this.map.setZoom(currentZoom);
      });
    }
  };

  updateRasterLayer = () => {
    if (this.map) {
      if (this.map.getSource("wms-test-source")) {
        this.map.removeLayer("wms-test-layer");
        this.map.removeSource("wms-test-source");
      }
      this.addRasterLayer();
    }
  };

  addRasterLayer = () => {
    console.log("Raster");
    if (this.map.getSource("wms-test-source")) {
      this.map.removeLayer("wms-test-layer");
      this.map.removeSource("wms-test-source");
    }

    const { map_response } = this.props;
    const selectedLayer = this.state.selectedLayer || "rainfall_flood"; // Default to rainfall_flood

    let baseUrl;
    if (this.state.variable === "storm_surge") {
      baseUrl = `https://devmap.intensel.net/storm_surge/tile/{x}/{y}/{z}?scenario_year=${this.state.scenario}_${this.state.year}`;
    } else {
      const countryCode =
        this.state.dataSource.length > 0
          ? this.state.dataSource[0].country_code
          : "HKG";
      baseUrl = `https://devmap.intensel.net/rainfall_flood/tile/{x}/{y}/{z}?file=${this.state.return_period}yr&country_code=${countryCode}`;
    }

    const tileUrl =
      map_response.subregion && this.state.variable !== "storm_surge"
        ? `${baseUrl}&region_code=${map_response.subregion}`
        : baseUrl;

    this.map.addSource("wms-test-source", {
      type: "raster",
      tiles: [tileUrl],
      tileSize: 256,
    });

    this.map.addLayer({
      id: "wms-test-layer",
      type: "raster",
      source: "wms-test-source",
      paint: {},
    });

    this.rasterLayerAdded = true;
  };

  addLegendControl = () => {
    console.log("legend");
    const legend = new LegendControl();
    this.map.addControl(legend, "bottom-right");
  };
  handleMapHover = (e) => {
    const longitude = e.lngLat.lng;
    const latitude = e.lngLat.lat;
    const country_code = this.state.dataSource[0]?.country_code || "";
    const { map_response } = this.props;

    console.log("Map hover coordinates:", { longitude, latitude });

    let formdata = new FormData();
    formdata.append("latitude", latitude);
    formdata.append("longitude", longitude);
    formdata.append("country_code", country_code);
    formdata.append("duration_data", this.state.duration_data);
    formdata.append("variable", this.state.variable);
    if (map_response.subregion != null) {
      formdata.append("subregion", map_response.subregion);
    }

    this.props.MapValue(formdata);

    this.setState({ longitude, latitude }, () => {
      console.log("State updated with new coordinates:", {
        longitude,
        latitude,
      });
      // this.showPopup(longitude, latitude);
    });
  };

  // Separate function to show the popup
  showPopup = (lng, lat) => {
    const {
      hand,
      elevation,
      no_of_days,
      slope,
      flood,
      value,
    } = this.state.mapValueResponse;
    const hazard =
      this.state.variable === "rainfall_flood" ? "Flood" : "Storm Surge";

    console.log("Popup data:", {
      hand,
      elevation,
      no_of_days,
      slope,
      flood,
      value,
    });

    if (this.popup) {
      this.popup.remove();
    }

    if (this.map && hand != null && elevation != null) {
      new mapboxgl.Popup()
        .setLngLat([lng, lat])
        .setHTML(
          `${hazard} (in m): ${value || "0"}<br/>
                Elevation (in m): ${elevation || "0"}<br/>
                Hand: ${hand || "0"}<br/>
                Number of Days: ${no_of_days || "0"}<br/>
                Slope: ${slope || "0"}&deg;`
        )
        .addTo(this.map);
    } else {
      console.error("Popup data is missing or map is not initialized");
    }
  };

  removePopup = () => {
    this.popup.remove();
  };

  handleAnalyse = (record) => {
    this.setState({ selectedCity: record, modalVisible: true });
  };

  closeModal = () => {
    this.setState({ modalVisible: false });
    if (this.map) {
      this.map.remove();
      this.map = null;
    }
  };

  handleMapStyleChange = (checked) => {
    const newStyle = checked
      ? "mapbox://styles/mapbox/dark-v10"
      : "mapbox://styles/mapbox/satellite-v9";
    this.setState({ mapStyle: newStyle }, this.updateMapStyle);
  };
  getRainfallFloodColumns = () => {
    return [
      { title: "Country Code", dataIndex: "country_code", key: "country_code" },

      {
        title: "Population Density (per sqkm)",
        dataIndex: "populationDensity",
        key: "populationDensity",
        render: (text) => (text ? text.toFixed(2) + " people/sqkm" : "0"),
      },
      {
        title: "Rainfall Flood (in m)",
        dataIndex: "rainfall_flood_avg",
        key: "rainfall_flood_avg",
        render: (text) => (text ? text.toFixed(2) : "0"),
      },

      {
        title: "Rainfall Flood Max (in m)",
        dataIndex: "rainfall_flood_max",
        key: "rainfall_flood_max",
        render: (text) => (text ? text.toFixed(2) : "0"),
      },
      {
        title: "Rainfall Flood Min (in m)",
        dataIndex: "rainfall_flood_min",
        key: "rainfall_flood_min",
        render: (text) => (text ? text.toFixed(2) : "0"),
      },
      // Add more columns as needed...
    ];
  };

  getStormSurgeColumns = () => {
    return [
      { title: "Country Code", dataIndex: "country_code", key: "country_code" },
      {
        title: "Population Density (per sqkm)",
        dataIndex: "populationDensity",
        key: "populationDensity",
        render: (text) => (text ? text.toFixed(2) + " people/sqkm" : "0"),
      },
      {
        title: "Storm Surge (in m)",
        dataIndex: "storm_surge_avg",
        key: "storm_surge",
        render: (text) => (text ? text.toFixed(2) : "0"),
      },

      {
        title: "Storm Surge Max (in m)",
        dataIndex: "storm_surge_max",
        key: "storm_surge_max",
        render: (text) => (text ? text.toFixed(2) : "0"),
      },
      {
        title: "Storm Surge Min (in m)",
        dataIndex: "storm_surge_min",
        key: "storm_surge_min",
        render: (text) => (text ? text.toFixed(2) : "0"),
      },
      // Add more columns as needed...
    ];
  };

  updateTableColumns = (variable) => {
    let columns;
    if (variable === "rainfall_flood") {
      columns = this.getRainfallFloodColumns();
    } else if (variable === "storm_surge") {
      columns = this.getStormSurgeColumns();
    }

    this.setState({ columns });
  };

  handlePeriodChange = (e) => {
    const newPeriod = e.target.value;
    this.setState({ return_period: newPeriod }, () => {
      this.updateDataSource(
        this.state.dataSource,
        this.props.map_response,
        newPeriod
      );
      this.updateRasterLayer();
    });
  };

  handleScenarioChange = (checkedValues) => {
    this.setState({ selectedScenarios: checkedValues });
  };
  handleDownload = () => {
    let formdata = new FormData();
    formdata.append("project_name", this.state.name);

    //formdata.append("scenarios", JSON.stringify(this.state.scenario_download));
    //formdata.append("years", JSON.stringify(this.state.year_download));
    this.props.downloadMap(formdata);
  };

  addLegendToMap = () => {
    const legend = document.createElement("div");
    legend.className = "legend";

    const legendItems = [
      { color: "#faffff", label: "0-0.1" },
      { color: "#d3fad3", label: "0.1-0.2" },
      { color: "#acf5a8", label: "0.2-0.5" },
      { color: "#56c5b8", label: "0.5-0.75" },
      { color: "#0096c8", label: "0.75-1" },
      { color: "#00507d", label: "1-1.5" },
      { color: "#000a32", label: "1.5-2" },
      { color: "#000519", label: ">2" },
    ];

    legendItems.forEach((item) => {
      const legendItem = document.createElement("div");
      legendItem.className = "legend-item";

      const legendColor = document.createElement("div");
      legendColor.className = "legend-color";
      legendColor.style.backgroundColor = item.color;

      const legendLabel = document.createElement("div");
      legendLabel.className = "legend-label";
      legendLabel.textContent = item.label;

      legendItem.appendChild(legendColor);
      legendItem.appendChild(legendLabel);
      legend.appendChild(legendItem);
    });

    // Add the legend to the map container
    this.map.getContainer().appendChild(legend);
  };
  handleExportImage = () => {
    const { name } = this.state;
    const mapContainerId = "capture";
    const increasedWidth = 1800;
    const increasedHeight = 1800;
    const logoElementId = "mapLogo";
    const fullscreenWidgetClass = "mapboxgl-ctrl-fullscreen";
    const scaleElementId = "scale"; // Element to hide during export

    const loadDelay = 8000; // Adjust the delay time in milliseconds as needed

    // Function to format the current date and time
    const getFormattedTimestamp = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");
      return `${year}${month}${day}_${hours}${minutes}${seconds}`;
    };

    const mapElement = document.getElementById(mapContainerId);
    const logoElement = document.getElementById(logoElementId);
    const fullscreenWidget = document.querySelector(
      `.${fullscreenWidgetClass}`
    );
    const scaleElement = document.getElementById(scaleElementId); // Get scale element

    if (this.mapContainer.current) {
      const initialWidth = this.mapContainer.current.style.width;
      const initialHeight = this.mapContainer.current.style.height;

      this.mapContainer.current.style.width = `${increasedWidth}px`;
      this.mapContainer.current.style.height = `${increasedHeight}px`;

      this.map.resize();

      setTimeout(() => {
        this.map.flyTo({
          center: [this.state.longitude, this.state.latitude],
          zoom: 15,
          speed: 4,
        });

        const waitForMap = new Promise((resolve) => {
          this.map.once("moveend", resolve);
        });

        waitForMap.then(() => {
          // Show the logo and hide the scale
          if (logoElement) {
            logoElement.style.display = "block";
          }
          if (fullscreenWidget) {
            fullscreenWidget.style.display = "none";
          }
          if (scaleElement) {
            scaleElement.style.display = "none";
          }

          html2canvas(mapElement, {
            useCORS: true,
            backgroundColor: null,
            width: increasedWidth,
            height: increasedHeight,
          })
            .then((canvas) => {
              const link = document.createElement("a");
              const timestamp = getFormattedTimestamp();
              link.href = canvas.toDataURL("image/png");
              link.download = `${name}_${timestamp}.png`;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);

              // Hide the logo and show the scale again
              if (logoElement) {
                logoElement.style.display = "none";
              }
              if (fullscreenWidget) {
                fullscreenWidget.style.display = "block";
              }
              if (scaleElement) {
                scaleElement.style.display = "block";
              }

              // After downloading, reset map container dimensions
              this.mapContainer.current.style.width = initialWidth;
              this.mapContainer.current.style.height = initialHeight;
              this.map.resize();
            })
            .catch((error) => {
              console.error("html2canvas error: ", error);

              // Hide the logo and show the scale again in case of error
              if (logoElement) {
                logoElement.style.display = "none";
              }
              if (fullscreenWidget) {
                fullscreenWidget.style.display = "block";
              }
              if (scaleElement) {
                scaleElement.style.display = "block";
              }

              // Reset map container dimensions on error
              this.mapContainer.current.style.width = initialWidth;
              this.mapContainer.current.style.height = initialHeight;
              this.map.resize();
            });
        });
      }, loadDelay);
    } else {
      console.error("Map container ref not found.");
    }
  };

  handleYearChange = (e) => {
    this.setState({ year: e.target.value });
  };

  handleRCPChange = (value) => {
    console.log("value", value);

    if (value.includes("_")) {
      const [scenario, year] = value.split("_");
      this.setState({
        scenario: scenario,
        year: year,
      });
    } else {
      this.setState({
        return_period: value,
      });
    }
    this.setState({ duration_data: value });
  };

  handleYearChange_download = (checkedValues) => {
    this.setState({ year_download: checkedValues });
  };
  handleRCPChange_download = (checkedValues) => {
    this.setState({ scenario_download: checkedValues });
  };

  openFilterModal = () => {
    this.setState({ filterModalVisible: true });
  };

  closeFilterModal = () => {
    this.setState({ filterModalVisible: false });
  };
  openDownloadModal = () => {
    this.setState({ downloadModalVisible: true });
  };

  closeDownloadModal = () => {
    this.setState({ downloadModalVisible: false });
  };

  applyFilter = () => {
    // Handle the filter application logic based on the filterType
    if (this.state.variable === "storm_surge") {
      const { scenario, year } = this.state;
      const updatedDataSource = this.updateDataSource(
        this.state.dataSource,
        this.props.map_response,
        scenario,
        year
      );
      this.setState({ dataSource: updatedDataSource }, this.updateRasterLayer);
    } else if (this.state.variable === "rainfall_flood") {
      const { return_period } = this.state;
      // Assuming you have the logic to update the dataSource based on return_period
      const updatedDataSource = this.updateDataSource(
        this.state.dataSource,
        this.props.map_response,
        return_period
      );
      this.setState({ dataSource: updatedDataSource }, this.updateRasterLayer);
    }

    this.setState({ filterModalVisible: false });
  };

  // Update filter type
  handleFilterTypeChange = (e) => {
    this.setState({ filterType: e.target.value });
  };

  handleTableVisibility = (checked) => {
    this.setState({ showTable: checked });
  };

  render() {
    console.log("map value", this.state.dataSource);

    return (
      <div className="custom-table" style={{ marginTop: "3.2%" }}>
        <Button
          type="primary"
          onClick={this.openFilterModal}
          style={{
            float: "right",
            color: "black",
            backgroundColor: "rgb(255,171,0)",
            marginBottom: "16",
            fontFamily: "montserrat",
            border: "0px",
            marginLeft: "1%",
            fontWeight: "600",
          }}
        >
          Filter Options
        </Button>
        <Button
          type="primary"
          onClick={this.openDownloadModal}
          style={{
            float: "right",
            color: "black",
            backgroundColor: "rgb(255,171,0)",
            marginBottom: "16",
            fontFamily: "montserrat",
            border: "0px",
            marginLeft: "1%",
            fontWeight: "600",
          }}
        >
          Download
        </Button>

        <br />
        <br />
        {this.state.showTable && (
          <div>
            <Table
              dataSource={this.state.dataSource}
              columns={this.state.columns}
              bordered
              pagination={false}
            />
            <br />
          </div>
        )}

        <Switch
          checkedChildren="Street View"
          unCheckedChildren="Satellite View"
          onChange={this.handleMapStyleChange}
          style={{ marginBottom: 16 }}
        />
        {this.state.mapStyle.includes("dark") && (
          <Switch
            checkedChildren="3D"
            unCheckedChildren="2D"
            checked={this.state.is3DViewEnabled}
            onChange={this.handle3DViewChange}
            style={{ marginTop: "-15px" }}
          />
        )}

        <Switch
          className="hide"
          checkedChildren="Show Table"
          unCheckedChildren="Hide Table"
          onChange={this.handleTableVisibility}
          checked={this.state.showTable} // Make sure it's tied to state
          style={{ marginBottom: 16, float: "right" }}
        />

        <div style={{ position: "relative" }} id="capture">
          {/* Hidden Map Logo */}
          <Image
            id="mapLogo"
            src={maplogo}
            alt="Logo"
            style={{
              display: "none",
              position: "absolute",
              top: "10px",
              right: "50px",
              width: "100px",
              zIndex: 1000, // Ensure it is above the map
            }}
          />

          {/* Loader 
          {!this.state.isMapLoaded && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1000,
              }}
            >
              <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
              </div>
            </div>
            )}*/}

          {/* Map Container and Controls */}

          <div
            ref={this.mapContainer}
            style={{
              width: "100%",
              height: "800px",
            }}
          />

          {/* Scale Display */}
          <div
            id="scale"
            style={{
              position: "absolute",
              bottom: 10,
              left: 10,
              background: "white",
              padding: "5px",
              borderRadius: "3px",
            }}
          >
            Scale: {this.state.scale}
          </div>

          {/* Info Box for Storm Surge or Rainfall Flood */}
          <div
            style={{
              position: "absolute",
              top: 10,
              left: 10,
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "5px",
              boxShadow: "0 0 10px rgba(0,0,0,0.5)",
              opacity: "0.75",
            }}
          >
            {this.state.variable === "storm_surge" ? (
              <strong>
                Storm Surge {this.state.scenario / 10} Year {this.state.year}{" "}
                {this.state.distance} km
              </strong>
            ) : (
              <strong>
                Rainfall Flood 1:{this.state.return_period} Return Period{" "}
                {this.state.distance} km
              </strong>
            )}
            <br />
          </div>
          <div
            style={{
              position: "absolute",
              bottom: 50, // Just below the existing info box
              left: 10,
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "5px",
              boxShadow: "0 0 10px rgba(0,0,0,0.5)",
              opacity: "0.75",
            }}
          >
            <strong>Geolocation: {this.state.district}</strong>
          </div>
        </div>

        <Modal
          open={this.state.filterModalVisible}
          onClose={this.closeFilterModal}
        >
          <Modal.Header>Filter Options</Modal.Header>
          <Modal.Content>
            <Row>
              <Col span={12}>
                <label>Select Dataset:</label>
                <Select
                  onChange={this.handleRCPChange}
                  placeholder="Select Datasets for Analysis"
                  options={this.state.options}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={16}></Col>

              <Col span={8}>
                <Button key="cancel" onClick={this.closeFilterModal}>
                  Cancel
                </Button>
                <Button key="apply" type="primary" onClick={this.applyFilter}>
                  Apply Filter
                </Button>
              </Col>
            </Row>
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.downloadModalVisible}
          onClose={this.closeDownloadModal}
        >
          <Modal.Header>Download Options</Modal.Header>
          <Modal.Content>
            <Radio.Group
              value={this.state.filterType}
              onChange={this.handleFilterTypeChange}
            >
              <Radio value="scenarioYear">Data</Radio>
              <Radio value="returnPeriod">Map</Radio>
            </Radio.Group>

            {this.state.filterType === "scenarioYear" ? (
              <>
                <Row></Row>
                <Row>
                  {this.props.download_map && this.props.download_map.url ? (
                    <a
                      href={this.props.download_map.url}
                      style={{ float: "right", marginLeft: "1%" }}
                    >
                      <Button
                        style={{
                          backgroundColor: "#18202F",
                          color: "white",
                          border: "0px",
                          float: "right",
                          marginLeft: "1%",
                        }}
                      >
                        Download Excel
                      </Button>
                    </a>
                  ) : (
                    <Button
                      style={{
                        backgroundColor: "#18202F",
                        color: "white",
                        border: "0px",
                        float: "right",
                        marginLeft: "1%",
                      }}
                      onClick={this.handleDownload}
                    >
                      Download Data
                    </Button>
                  )}
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Button
                    style={{
                      backgroundColor: "#18202F",
                      color: "white",
                      border: "0px",
                      float: "right",
                    }}
                    onClick={this.handleExportImage}
                  >
                    Download Map
                  </Button>
                </Row>
              </>
            )}
            <Row>
              <Col span={20}></Col>

              <Col span={4}>
                <Button key="cancel" onClick={this.closeDownloadModal}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    map_response: state.locus.map_response,
    download_map: state.locus.download_map,
    download_map_value: state.locus.download_map_value,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    generateMap: (formdata) => {
      dispatch(locus.generateMap(formdata));
    },
    MapValue: (formdata) => {
      dispatch(locus.MapValue(formdata));
    },
    downloadMap: (formdata) => {
      return dispatch(locus.downloadMap(formdata));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DownloadMap);
