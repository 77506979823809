import React, { Component } from "react";
import {
  Form,
  Button,
  Input,
  TextArea,
  Grid,
  Container,
  Message,
  Image,
  Header,
  Menu,
  Select,
  Dropdown,
  Icon,
  Table,
  Modal,
  Popup,
  StepTitle,
  Progress,
} from "semantic-ui-react";
import logo from "../assets/logo.png";
import "./project.css";
import { connect } from "react-redux";
import { locus, auth, project } from "../actions";
import { Row, Col, Card, Tabs, Tab } from "react-bootstrap";
import Spinner from "./loader";
import CsvDownloadButton from "react-json-to-csv";
import dropbox from "../assets/dropbox.png";
import team1 from "../assets/team_1.png";
import team2 from "../assets/team_2.png";

import var_eg from "../assets/var_eg.jpg";
//import Report from "./report";
import { Popconfirm } from "antd";
import "antd/dist/antd.css";
import pptxgen from "pptxgenjs";
import { runppt } from "./ppt";
import { changeTitle } from "../redux/actions";
import { Redirect, withRouter } from "react-router-dom";
import HorizontalTimeline from "react-horizontal-timeline";

let options = [];

const VALUES = [
  "2021-1-02",
  "2021-1-01",
  "2021-2-01",
  "2021-3-01",
  "2021-4-01",
  "2021-5-01",
  "2021-6-01",
  "2021-7-01",
  "2021-8-01",
  "2021-9-01",
  "2021-10-01",
  "2021-11-01",
  "2021-12-01",
];

const StatusOptions = [
  { key: "active", value: "Active", text: "Active" },
  { key: "finished", value: "Finished", text: "Finished" },
];
const csv = [];
const othersOptions = [
  { key: "valuation", value: "valuation", text: "valuation" },
  { key: "type", value: "type", text: "type" },
  { key: "Area", value: "Area", text: "Area" },
  { key: "Ownership", value: "ownership", text: "Ownership" },
  { key: "floors", value: "floors", text: "floors" },
  {
    key: "Construction Cost",
    value: "construction_cost",
    text: "Construction Cost",
  },
  { key: "With Content", value: "With Content", text: "With Content" },
];
let variableOptions = [];
const rcpOptions = [
  { key: "0.0", value: "0.0", text: "0.0" },
  { key: "2.6", value: "2.6", text: "2.6" },
  { key: "4.5", value: "4.5", text: "4.5" },
  { key: "8.5", value: "8.5", text: "8.5" },
];
const yearOptions = [
  { key: "2020", value: "2020", text: "2020" },
  { key: "2030", value: "2030", text: "2030" },
  { key: "2050", value: "2050", text: "2050" },
];
const PortfolioLossOptions = [
  {
    key: "Rainfall Flood Loss",
    value: "Rainfall Flood Loss",
    text: "Rainfall Flood Loss",
  },
  {
    key: "River Flood Loss",
    value: "River Flood Loss",
    text: "River Flood Loss",
  },
  {
    key: "Storm Surge Loss",
    value: "Storm Surge Loss",
    text: "Storm Surge Loss",
  },
  { key: "Typhoon Loss", value: "Typhoon Loss", text: "Typhoon Loss" },
  {
    key: "Temprature/heat Loss",
    value: "Temprature/heat Loss",
    text: "Temprature/heat Loss",
  },
  {
    key: "Solar Production Loss",
    value: "Solar Production Loss",
    text: "Solar Production Loss",
  },
  {
    key: "Wind Production Loss",
    value: "Wind Production Loss",
    text: "Wind Production Loss",
  },
  {
    key: "Hydro Production Loss",
    value: "Hydro Production Loss",
    text: "Hydro Production Loss",
  },
  {
    key: "Wheat Production Loss",
    value: "Wheat Production Loss",
    text: "Wheat Production Loss",
  },
  {
    key: "Rice Production Loss",
    value: "Rice Production Loss",
    text: "Rice Production Loss",
  },
  {
    key: "Maize Production Loss",
    value: "Maize Production Loss",
    text: "Maize Production Loss",
  },
  {
    key: "Fruit Production Loss",
    value: "Fruit Production Loss",
    text: "Fruit Production Loss",
  },
  {
    key: "Vegetable Production Loss",
    value: "Vegetable Production Loss",
    text: "Vegetable Production Loss",
  },
];

class MapProject extends Component {
  state = {
    name: "",
    description: "",
    status: "",
    assets: [],
    loading: false,
    activeItemName: "",
    modalOpen: false,
    variables: [],
    others: "",
    year: "",
    rcp: "",
    modalloading: false,
    lossmodalOpen: false,
    reportmodal: false,
    portfolios_transition: "",
    downloadmodalOpen: false,
    new_project: "",
    portfolio_loss: "",
    modalEdit: false,
    loading: false,
    dropbox: true,
    value: 0,
    previous: 0,
    share: false,
  };

  componentDidMount() {
    this.props.getProjectList("map_module");
  }

  handleLogout = () => {
    this.props.logout();
  };

  handleModify = (portfolio) => {
    console.log("map_portf", portfolio);
    this.props.history.push({
      pathname: "/map",
      state: { name: portfolio },
    });
  };

  render() {
    const {
      value,
      others,
      variables,
      rcp,
      year,
      status,
      portfolio_loss,
    } = this.state;
    let user_id = localStorage.getItem("user_id");

    if (this.state.loading) {
      return <div id="loading"></div>;
    }
    if (this.props.list && this.props.list.length > 0) {
      let assets = this.props.list;
      this.state.portfolios_map = assets;
    }
    console.log("portfolio map", this.state.portfolios_map);

    return (
      <div className="project_card" style={{ padding: "1%" }}>
        <Row>
          {this.props.list && this.props.list.length > 0 ? (
            <Col lg={12} className="card_eg_top">
              <Row>
                {" "}
                <Col className="card_info">
                  <p className="card_info2">
                    <span className="card_info2a">
                      All Projects For &nbsp;{" "}
                    </span>

                    {this.props.list.username}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="card_info">
                  <p className="card_info3">
                    <span className="card_info3a">
                      Total Number of Projects &nbsp;
                    </span>

                    {this.props.list.length}
                  </p>
                </Col>
              </Row>
            </Col>
          ) : null}
        </Row>
        <Row>
          <Col lg={12}>
            <Row>
              {this.state.portfolios_map && this.state.portfolios_map.length > 0
                ? this.state.portfolios_map.map((portfolio, index) => (
                    <Col lg={4}>
                      <Card className="card_eg" key={index}>
                        <Card.Body>
                          <Card.Title
                            style={{ fontWeight: "bold" }}
                            className="card_title"
                          >
                            {portfolio.name}{" "}
                            {portfolio.created_at ? (
                              <span
                                style={{
                                  float: "right",
                                  fontSize: "12px",
                                  fontWeight: "normal",
                                }}
                              >
                                {portfolio.created_at.slice(0, 10)}
                              </span>
                            ) : null}
                          </Card.Title>

                          <p></p>
                          <br />
                          <Row>
                            <Col></Col>
                            <Col lg={4}>
                              <Button
                                style={{
                                  backgroundColor: "#355ed814",
                                  color: "#18202F",
                                  fontSize: "16px",

                                  borderRadius: "2px",
                                }}
                                variant="primary"
                                onClick={() => this.handleModify(portfolio)}
                              >
                                Analyse
                              </Button>
                            </Col>

                            <Col></Col>
                            {/* <Col lg={6}>  <Button style={{backgroundColor:'#FDB827', color:'white'}} variant="primary" onClick={()=>this.handleDetailedReport(portfolio)}>TCFD Report</Button></Col>*/}
                          </Row>
                          <br />
                        </Card.Body>
                      </Card>
                    </Col>
                  ))
                : null}
            </Row>
          </Col>
        </Row>

        <Modal
          open={this.state.downloadmodalOpen}
          onClose={this.handleDownloadModalClose}
          closeIcon
          itemName={this.state.activeItemName}
        >
          <Modal.Header>Download Carbon Module Output</Modal.Header>
          <Modal.Content scrolling>
            <div style={{ marginLeft: "20%", marginRight: "20%" }}>
              <Grid.Row>
                <Form.Field
                  id="form-input-control-project"
                  control={Input}
                  label="Portfolio"
                  placeholder={this.state.activeItemName}
                  disabled
                  style={{ width: "100%" }}
                />
              </Grid.Row>
              <br />

              {this.state.modalloading &&
              !this.props.download_carbon_project_data.length > 0 ? (
                <Button
                  style={{
                    backgroundColor: "#fdb827",
                    marginLeft: "45%",
                  }}
                >
                  <Spinner />
                </Button>
              ) : (
                <Button
                  style={{
                    backgroundColor: "#18202F",
                    color: "white",
                    marginLeft: "40%",
                  }}
                  onClick={() => this.handleDownload(this.state.activeItemName)}
                  primary
                >
                  Submit
                </Button>
              )}

              {this.props.download_carbon_project_data.length > 0 ? (
                <div>
                  <CsvDownloadButton
                    data={this.props.download_carbon_project_data}
                    delimiter=","
                    style={{
                      backgroundColor: "#18202F",
                      color: "white",
                      border: "0px solid white",
                      padding: "10px",
                      float: "right",
                      borderRadius: "5%",
                      fontWeight: "bold",
                    }}
                  />
                </div>
              ) : null}
            </div>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.project.errors,
    locus: state.locus.locus,
    csv: state.project.csv,
    project: state.project.project,
    ppt: state.project.ppt,
    map: state.project.map,
    overall_map: state.project.overall_map,
    link: state.project.link,
    list: state.project.list,
    download_carbon_project_data: state.project.download_carbon_project_data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLocations: () => {
      dispatch(locus.getLocations());
    },
    logout: () => {
      dispatch(auth.logout());
    },
    addProject: (formdata) => {
      dispatch(project.addProject(formdata));
    },
    getCSV: (formdata) => {
      dispatch(project.getCSV(formdata));
    },
    getLoss: (formdata) => {
      dispatch(project.getLoss(formdata));
    },
    getSummary: (formdata) => {
      dispatch(project.getSummary(formdata));
    },
    changeTitle: (title) => {
      dispatch(changeTitle(title));
    },
    getProjects: () => {
      dispatch(project.getProjects());
    },
    deleteProject: (formdata) => {
      dispatch(project.deleteProject(formdata));
    },
    getPptReport: (formdata) => {
      dispatch(project.getPptReport(formdata));
    },
    getSatelliteMap: (formdata) => {
      dispatch(project.getSatelliteMap(formdata));
    },
    getMap: (formdata) => {
      dispatch(project.getMap(formdata));
    },
    Dropbox: (formdata) => {
      dispatch(project.Dropbox(formdata));
    },
    getProjectList: (type) => {
      dispatch(project.getProjectList(type));
    },
    getTransitionProject: () => {
      dispatch(project.getTransitionProject());
    },
    deleteCarbonProject: (formdata) => {
      dispatch(project.deleteCarbonProject(formdata));
    },
    downloadCarbonProject: (formdata) => {
      dispatch(project.downloadCarbonProject(formdata));
    },
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MapProject)
);
