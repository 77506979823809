import React, { Component } from "react";
import {
  Form,
  Button,
  Input,
  TextArea,
  Grid,
  Container,
  Message,
  Image,
  Header,
  Menu,
  Select,
  Dropdown,
  Icon,
  Table,
  Label,
  Modal,
  Popup,
  StepTitle,
  GridColumn,
} from "semantic-ui-react";
import logo from "../assets/logo.png";
import "./project.css";
import { connect } from "react-redux";
import { locus, auth, project } from "../actions";
import { Row, Col, Card, Tabs, Tab } from "react-bootstrap";
import Spinner from "./loader";
import CsvDownloadButton from "react-json-to-csv";
import dropbox from "../assets/dropbox.png";
import team1 from "../assets/team_1.png";
import team2 from "../assets/team_2.png";

import pr1 from "../assets/pr1.png";
import pr2 from "../assets/pr2.png";
import pr3 from "../assets/pr3.png";

import var_eg from "../assets/var_eg.jpg";
//import Report from "./report";
import { Popconfirm, Progress, Checkbox } from "antd";
import "antd/dist/antd.css";
import pptxgen from "pptxgenjs";
import { runppt } from "./ppt";
import { changeTitle } from "../redux/actions";
import { Redirect, withRouter } from "react-router-dom";
import HorizontalTimeline from "react-horizontal-timeline";
import TransitionProject from "./transition_project";
import RealEstateProject from "./realestate_project";
import ProjectBarComponent from "./project_bar_component";
import { GROUP_NODE_RENDER_ID } from "@antv/xflow-extension/es/flowchart-node-panel";
import { formatDefaultLocale, timeThursdays } from "d3";

let options = [];

const ScenarioOptions = [
  {
    key: "rcp",
    value: "rcp",
    text: "RCP",
  },
  {
    key: "ssp",
    value: "ssp",
    text: "SSP",
  },
];

const ProjectOptions = [
  { key: "physical", value: "physical", text: "Physical Risk" },
  { key: "transition", value: "transition", text: "Transition Risk" },
  { key: "realestate", value: "realestate", text: "Real Estate" },
];
const StatusOptions = [
  { key: "active", value: "Active", text: "Active" },
  { key: "finished", value: "Finished", text: "Finished" },
];
const csv = [];
const othersOptions = [
  { key: "valuation", value: "valuation", text: "valuation" },
  { key: "type", value: "type", text: "type" },
  { key: "Area", value: "Area", text: "Area" },
  { key: "Ownership", value: "ownership", text: "Ownership" },
  { key: "floors", value: "floors", text: "floors" },
  {
    key: "Construction Cost",
    value: "construction_cost",
    text: "Replacement Value",
  },
  //{ key: "With Content", value: "with_content", text: "With Content" },
  { key: "Revenue", value: "revenue", text: "Revenue" },
];

let variableOptions = [];
const rcpOptions = [
  { key: "2.6", value: "2.6", text: "2.6" },
  { key: "4.5", value: "4.5", text: "4.5" },
  { key: "8.5", value: "8.5", text: "8.5" },
];
const rcp_2020_Options = [
  { key: "2.6", value: "2.6", text: "2.6" },
  { key: "4.5", value: "4.5", text: "4.5" },
  { key: "8.5", value: "8.5", text: "8.5" },
];
const yearOptions = [
  { key: "2020", value: "2020", text: "2020" },
  { key: "2030", value: "2030", text: "2030" },
  { key: "2050", value: "2050", text: "2050" },
];
const yearOptions_default = [
  { key: "2030", value: "2030", text: "2030" },
  { key: "2050", value: "2050", text: "2050" },
];
const contentOptions = [
  { key: "with_content", value: "with_content", text: "With Content" },
  { key: "without_content", value: "without_content", text: "Without Content" },
];

const sspyearOptions = [
  { key: "2020", value: "2020", text: "2020" },
  { key: "2025", value: "2025", text: "2025" },
  { key: "2030", value: "2030", text: "2030" },
  { key: "2035", value: "2035", text: "2035" },
  { key: "2040", value: "2040", text: "2040" },
  { key: "2045", value: "2045", text: "2045" },
  { key: "2050", value: "2050", text: "2050" },
  { key: "2055", value: "2055", text: "2055" },
  { key: "2060", value: "2060", text: "2060" },
  { key: "2065", value: "2065", text: "2065" },
  { key: "2070", value: "2070", text: "2070" },
  { key: "2075", value: "2075", text: "2075" },
  { key: "2080", value: "2080", text: "2080" },
  { key: "2085", value: "2085", text: "2085" },
  { key: "2090", value: "2090", text: "2090" },
  { key: "2095", value: "2095", text: "2095" },
  { key: "2100", value: "2100", text: "2100" },
];
const sspyearOptions_default = [
  { key: "2030", value: "2030", text: "2030" },
  { key: "2050", value: "2050", text: "2050" },
  { key: "2100", value: "2100", text: "2100" },
];

const agriOptions = [
  { key: "Wheat", value: "wheat", text: "Wheat" },
  { key: "rice", value: "rice", text: "Rice" },
  { key: "maize", value: "maize", text: "Maize" },
  { key: "fruit", value: "fruit", text: "Fruit" },
];
const energyOptions = [
  { key: "solar", value: "solar", text: "Solar" },
  { key: "wind", value: "wind", text: "Wind" },
  { key: "hydro", value: "Hydro", text: "Hydro" },
];

const currencyOptions = [
  { key: "EUR", value: "EUR", text: "Euro" },
  { key: "USD", value: "USD", text: "US Dollars" },
  { key: "HKD", value: "HKD", text: "HongKong Dollars" },
  { key: "GBP", value: "GBP", text: "Pound" },
];
let userOptions = [];
const gdpOptions = [
  {
    key: "unadjustd",
    value: false,
    text: "Unadjusted Operational Loss",
  },
  {
    key: "adjusted",
    value: true,
    text: "Operational Loss Adjusted with Long-term Climate Change Impact",
  },
];
const valuationOptions = [
  {
    key: "replacement",
    value: true,
    text: "Yes",
  },
  {
    key: "valuation",
    value: false,
    text: "No",
  },
];

const permissionOptions = [
  { key: "read", value: "read", text: "read" },
  { key: "write", value: "write", text: "write" },
];

const MetigationOptions = [
  { key: "Rainfall Flood", value: true, text: "Yes" },
  { key: "River Flood", value: false, text: "No" },
];
let PortfolioLossOptions = [
  {
    key: "River Flood Loss",
    value: "River Flood Loss",
    text: "River Flood Loss",
  },
  {
    key: "Storm Surge Loss",
    value: "Storm Surge Loss",
    text: "Storm Surge Loss",
  },
  { key: "Typhoon Loss", value: "Typhoon Loss", text: "Typhoon Loss" },

  /*{
    key: "Solar Production Loss",
    value: "Solar Production Loss",
    text: "Solar Production Loss",
  },
  {
    key: "Wind Production Loss",
    value: "Wind Production Loss",
    text: "Wind Production Loss",
  },
  {
    key: "Hydro Production Loss",
    value: "Hydro Production Loss",
    text: "Hydro Production Loss",
  },
  {
    key: "Wheat Production Loss",
    value: "Wheat Production Loss",
    text: "Wheat Production Loss",
  },
  {
    key: "Rice Production Loss",
    value: "Rice Production Loss",
    text: "Rice Production Loss",
  },
  {
    key: "Maize Production Loss",
    value: "Maize Production Loss",
    text: "Maize Production Loss",
  },
  {
    key: "Fruit Production Loss",
    value: "Fruit Production Loss",
    text: "Fruit Production Loss",
  },
  {
    key: "Vegetable Production Loss",
    value: "Vegetable Production Loss",
    text: "Vegetable Production Loss",
  },*/
];
let tierdata = [];

class PhysicalProject extends Component {
  state = {
    name: "",
    description: "",
    status: "",
    assets: [],
    loading: false,
    activeItemName: "",
    modalOpen: false,
    variables: [],
    others: "",
    filterOpen: false,
    agri: "",
    csv_rcp: "",
    csv_scenario: "",
    csv_year: "",
    energy: "",
    content: "",
    year: "",
    currency: "",
    rcp: "",
    permission: "",
    use_valuation: false,
    metigation: "",
    user: "",
    modalloading: false,
    lossmodalOpen: false,
    reportmodal: false,
    portfolios: "",
    summarymodalOpen: false,
    new_project: "",
    gdp: "",
    valuation: "",
    portfolio_loss: "",
    modalEdit: false,
    modalShare: false,
    loading: false,
    dropbox: true,
    value: 0,
    previous: 0,
    share: false,
    project_type: "physical",
    scenario: "",
    project_view: false,
    project_id: "",
  };

  componentDidMount() {
    this.props.getProjectList("physical_risk");
    tierdata = JSON.parse(localStorage.getItem("tier_data"));
  }
  componentDidUpdate(prevProps) {
    if (prevProps.list_physical !== this.props.list_physical) {
      this.setState({
        scenario: this.props.list_physical.default_filters.analysis_type,
        year: this.props.list_physical.default_filters.year,
        rcp: this.props.list_physical.default_filters.scenario,
      });
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    let formdata = new FormData();
    formdata.append("oldname", this.state.activeItemName);
    formdata.append("status", this.state.status);
    formdata.append("description", this.state.description);
    formdata.append("name", this.state.new_project);
    this.setState({ loading: true }, () => {
      this.props.addProject(formdata);
    });
  };
  onShareSubmit = (e) => {
    e.preventDefault();
    let formdata = new FormData();
    formdata.append("project_id", this.state.project_id);
    formdata.append("share_with", JSON.stringify(this.state.user));
    formdata.append("permission", this.state.permission);
    this.setState({ loading: true }, () => {
      this.props.shareProject(formdata);
    });
  };
  handleChange = (value, key) => {
    this.setState({ [key]: value });
  };
  handleProjectView = () => {
    this.props.history.push({ pathname: "/location" });
  };
  useValuation = (e) => {
    this.setState({ use_valuation: !this.state.use_valuation });
  };

  handlePptReport = () => {
    let formdata = new FormData();
    // formdata.append("use_valuation", this.state.use_valuation);
    // formdata.append("currency", this.state.currency);
    formdata.append("project_id", this.state.project_id);
    formdata.append("analysis_type", this.state.scenario);

    this.setState({ loading: true }, () => {
      this.props.getPptReport(formdata);
      setTimeout(() => {
        this.handleUrl();
      }, 3000);
    });
  };
  handleUrl() {
    if (this.props.ppt.url) {
      window.open(this.props.ppt.url, "_blank");
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let formdata = new FormData();
    formdata.append("project_name", this.state.activeItemName);
    formdata.append("climate_variables", JSON.stringify(this.state.variables));
    formdata.append("year_list", JSON.stringify(this.state.csv_year));
    formdata.append("scenario_list", JSON.stringify(this.state.csv_rcp));
    formdata.append("currency", this.state.currency);
    formdata.append("filters", JSON.stringify(this.state.content));
    formdata.append("adjusted_gdp", this.state.gdp);
    //formdata.append("agriculture_variables", JSON.stringify(this.state.agri));
    //formdata.append("energy_variables", JSON.stringify(this.state.energy));
    formdata.append("analysis_type", this.state.csv_scenario);
    formdata.append("has_tip", this.state.valuation);
    formdata.append("has_mitigation", this.state.metigation);
    formdata.append(
      "loss_variables",
      JSON.stringify(this.state.portfolio_loss)
    );

    this.setState({ modalloading: true }, () => {
      this.props.getCSV(formdata);
    });
  };
  handleUpdateFilter = (e) => {
    e.preventDefault();
    let formdata = new FormData();

    formdata.append(
      "data",
      JSON.stringify({
        year: this.state.year,
        scenario: this.state.rcp,
        analysis_type: this.state.scenario,
      })
    );
    this.setState({ modalloading: true, filterOpen: false }, () => {
      this.props.updateFilter(formdata);
    });
  };
  handleView = () => {
    console.log("view ", this.props.csv.success);
    this.props.history.push({
      pathname: "/pivottable",
      state: { data: this.props.csv.success },
    });
  };
  handleLossSubmit = (e) => {
    e.preventDefault();
    let formdata = new FormData();
    formdata.append("portfolio", this.state.activeItemName.slice(10));
    formdata.append("year", this.state.year);
    this.setState({ modalloading: true }, () => {
      this.props.getLoss(formdata);
    });
  };

  handleSummarySubmit = (e) => {
    e.preventDefault();
    let formdata = new FormData();
    formdata.append("project_name", this.state.activeItemName);
    formdata.append("has_mitigation", this.state.metigation);
    formdata.append("analysis_type", this.state.scenario);
    this.setState({ modalloading: true }, () => {
      this.props.getSummary(formdata);
    });
  };

  handleLogout = () => {
    this.props.logout();
  };
  handleVariable = () => {
    variableOptions = this.state.activeVariable.map((variable) => ({
      key: variable,
      value: variable,
      text: variable,
    }));
    console.log("variable", variableOptions);
    if (this.state.activeVariable.includes("Rainfall Flood")) {
      PortfolioLossOptions.push({
        key: "Rainfall Flood Loss",
        value: "Rainfall Flood Loss",
        text: "Rainfall Flood Loss",
      });
    }
    if (this.state.activeVariable.includes("Extreme Heat")) {
      PortfolioLossOptions.push({
        key: "Temprature/heat Loss",
        value: "Temprature/heat Loss",
        text: "Temperature/Heat Loss",
      });
    }
  };

  handleOpen = (portfolio, variable) => {
    this.setState(
      { modalOpen: true, activeItemName: portfolio, activeVariable: variable },
      () => this.handleVariable()
    );
  };

  handleEdit = (portfolio) => {
    this.setState({ modalEdit: true, activeItemName: portfolio });
  };
  handleShare = (portfolio) => {
    console.log("share", portfolio);
    this.setState({
      modalShare: true,
      activeItemName: portfolio.name,
      project_id: portfolio.project_id,
    });
  };

  handleLossModalOpen = (portfolio) =>
    this.setState({ lossmodalOpen: true, activeItemName: portfolio }, () =>
      console.log(this.state.activeItemName)
    );

  handleReport = (portfolio) =>
    this.setState(
      {
        reportmodal: true,
        activeItemName: portfolio.name,
        project_id: portfolio.project_id,
      },
      () => console.log("id", this.state.project_id)
    );
  handleFilterOpen = () => {
    this.setState({
      filterOpen: true,
    });
  };
  handleLossModalClose = () => {
    this.setState({ lossmodalOpen: false }, () =>
      window.location.reload(false)
    );
  };
  handleSummaryModalOpen = (portfolio) =>
    this.setState({ summarymodalOpen: true, activeItemName: portfolio });

  handleSummaryModalClose = () => {
    this.setState({ summarymodalOpen: false }, () =>
      window.location.reload(false)
    );
  };

  /* handleDropbox = (base, map) => {
    if (this.props.link.url == undefined) {
      console.log("function called", map);
      let formdata = new FormData();
      formdata.append("ppt", base);
      formdata.append("project_name", map);
      this.props.Dropbox(formdata);
    }
  };*/

  handleClose = () =>
    this.setState(
      {
        modalOpen: false,
        lossmodalOpen: false,
        reportmodal: false,
        modalEdit: false,
        dropbox: false,
        modalShare: false,
        filterOpen: false,
      },
      () => window.location.reload(false)
    );

  handleDashboard = (portfolio) => {
    localStorage.setItem("variables", portfolio.variables);
    this.props.history.push({
      pathname: "/dashboard",
      state: {
        project_name: portfolio.name,
        variables: portfolio.variables,
        property_type: portfolio.property_type,
        project_id: portfolio.project_id,
        scenario: this.state.scenario,
        rcp: this.state.rcp,
        year: this.state.year,
      },
    });
  };

  handleProject = (e, { value }) => {
    this.setState({ project_type: value });
  };
  handleDetailedReport = (portfolio) => {
    this.props.history.push({
      pathname: "/tcfd",
      state: { portfolio: portfolio.name },
    });
  };
  delete = (portfolio) => {
    let formdata = new FormData();
    formdata.append("name", portfolio);
    this.props.deleteProject(formdata);
  };

  render() {
    const {
      value,
      others,
      gdp,
      valuation,
      variables,
      rcp,
      csv_rcp,
      csv_scenario,
      csv_year,
      year,
      status,
      portfolio_loss,
      currency,
    } = this.state;

    console.log(
      "initial state",
      this.state.scenario,
      this.state.rcp,
      this.state.year,
      this.state.project_id
    );

    let user_id = localStorage.getItem("user_id");

    /* if (this.props.ppt.url && this.state.loading) {
      this.state.loading = false;

      let a = document.createElement("a");
      a.target = "_blank";
      a.href = this.props.ppt.url;
      a.click();
      window.location.reload();
    }
    if (this.state.loading) {
      return <div id="loading"></div>;
    }*/

    if (
      this.props.list_physical.data &&
      this.props.list_physical.data.length > 0
    ) {
      userOptions = [];

      let assets = this.props.list_physical.data;
      this.state.portfolios = assets;

      const members = this.props.list_physical.members;

      for (let i = 0; i < members.length; i++) {
        userOptions.push({
          key: members[i],
          value: members[i],
          text: members[i],
        });
      }
    }

    return (
      <div className="project_card" style={{ padding: "1%" }}>
        <Row>
          {this.props.list_physical.data &&
          this.props.list_physical.data.length > 0 ? (
            <Col lg={9} className="card_eg_top">
              <Row>
                {" "}
                <Col className="card_info">
                  <p className="card_info2">
                    <span className="card_info2a">All Projects For </span>{" "}
                    {this.props.list_physical.username}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="card_info">
                  <p className="card_info3">
                    <span className="card_info3a">Total Project </span>{" "}
                    {this.props.list_physical.data.length}
                  </p>
                </Col>
              </Row>
            </Col>
          ) : null}
          <Col lg={3}>
            <Button
              style={{
                float: "right",
                color: "#18202F",
                backgroundColor: "#355ED814",
                fontFamily: "montserrat",
              }}
              onClick={this.handleProjectView}
            >
              Create New Project
            </Button>
            <Button
              style={{
                float: "right",
                color: "black",
                backgroundColor: "rgb(255,171,0)",

                fontFamily: "montserrat",
              }}
              onClick={this.handleFilterOpen}
            >
              Advanced Filter
            </Button>
          </Col>
          {/* <Row>
                    <divgetProject
                    >
                      <HorizontalTimeline
                        index={this.state.value}
                        indexClick={(index) => {
                          this.setState({
                            value: index,
                            previous: this.state.value,
                          });
                        }}
                        values={VALUES}
                      />
                    </div>
                    <div></div>
                      </Row>*/}
        </Row>
        <Row>
          <Col lg={12}>
            <Row>
              {this.state.portfolios && this.state.portfolios.length > 0
                ? this.state.portfolios.map((portfolio, index) => (
                    <Col lg={4}>
                      <Card
                        className="card_eg"
                        key={index}
                        style={{ border: "1px solid lightgrey" }}
                      >
                        <Card.Body style={{ padding: "0%" }}>
                          {portfolio.name ? (
                            <Card.Title className="card_title">
                              {portfolio.name.slice(10)}
                              {portfolio.date ? (
                                <span
                                  style={{
                                    float: "right",
                                    fontSize: "12px",
                                    fontWeight: "normal",
                                  }}
                                >
                                  {portfolio.date.slice(0, 10)}
                                </span>
                              ) : null}
                            </Card.Title>
                          ) : null}
                          <p>
                            <span
                              style={{
                                fontWeight: "normal",
                                fontFamily: "Montserrat",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "16px",
                                lineHeight: "20px",
                                color: "#7A7878",
                              }}
                            >
                              Climate Score
                            </span>
                          </p>

                          <Progress percent={portfolio.overall} />
                          <br />

                          <Card.Text
                            style={{
                              paddingTop: "2%",
                              fontFamily: "montserrat",
                              fontStyle: "normal",
                              fontWeight: "400",
                              color: "#767676",
                              fontSize: "16px",
                              lineHeight: "20px",
                              letterSpacing: "0.05em",
                              wordSpacing: "0.25em",
                            }}
                          >
                            The project has {portfolio.assets} assets with
                            Maximum Exposure of{" "}
                            <span style={{ color: "green" }}>
                              {" "}
                              ${portfolio.value}&nbsp;
                            </span>
                            million and a Climate Value at Risk under RCP 8.5
                            as&nbsp;
                            {portfolio.loss ? (
                              <span style={{ color: "red" }}>
                                ${portfolio.loss.toFixed(2)}
                              </span>
                            ) : null}
                            &nbsp;million
                          </Card.Text>

                          <Row>
                            <Col></Col>
                            <Col lg={3}>
                              <Button
                                style={{
                                  backgroundColor: "#355ED814",
                                  color: "#18202F",
                                  fontSize: "16px",
                                  width: "120px",
                                  borderRadius: "2px",
                                }}
                                variant="primary"
                                onClick={() => this.handleDashboard(portfolio)}
                              >
                                Analyse
                              </Button>
                            </Col>
                            {tierdata &&
                            tierdata.download_options.includes(
                              "download_report"
                            ) ? (
                              <Col lg={3}>
                                <Button
                                  style={{
                                    backgroundColor: "#F6F6F6",
                                    color: "#18202F",
                                    fontSize: "16px",
                                    fontFamily: "montserrat",
                                    width: "120px",
                                    borderRadius: "2px",
                                  }}
                                  variant="primary"
                                  onClick={() => this.handleReport(portfolio)}
                                >
                                  Report
                                </Button>
                              </Col>
                            ) : null}

                            <Col></Col>
                            {/* <Col lg={6}>  <Button style={{backgroundColor:'#FDB827', color:'white'}} variant="primary" onClick={()=>this.handleDetailedReport(portfolio)}>TCFD Report</Button></Col>*/}
                          </Row>
                          <br />

                          <Row className="card_btm_row">
                            {/* <Col lg={6}>
                              {this.state.share ? (
                                <span style={{ marginTop: "0%" }}>
                                  <Dropdown
                                    placeholder="Share"
                                    fluid
                                    multiple
                                    selection
                                    options={options}
                                  />
                                </span>
                              ) : (
                                <span>
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      fontFamily: "montserrat",
                                    }}
                                  >
                                    Team
                                  </p>
                                  {portfolio.shared_with ? (
                                    <Row style={{ marginLeft: "2%" }}>
                                      {portfolio.shared_with.map(
                                        (share, index) => {
                                          console.log("share", share);
                                          return (
                                            <>
                                              <Col lg={4}>
                                                <Popup
                                                  content={share}
                                                  trigger={
                                                    <Image
                                                      src={team1}
                                                      size="mini"
                                                    />
                                                  }
                                                />
                                              </Col>
                                            </>
                                          );
                                        }
                                      )}
                                      <Col lg={4}>
                                        <p style={{ width: "70px" }}>
                                          +{portfolio.shared_with.length}
                                          {""} more
                                        </p>
                                      </Col>
                                    </Row>
                                  ) : null}
                                </span>
                              )}
                                      </Col>*/}
                            <Col lg={1}></Col>
                            <Col lg={10}>
                              {/* <Row>
                                <Col lg={2}></Col>
                                <Col lg={5}>
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      fontFamily: "montserrat",
                                      marginBottom: "0em",
                                    }}
                                  >
                                    Download CSVs
                                  </p>

                                  {tierdata &&
                                  tierdata.download_options.includes(
                                    "download_csv"
                                  ) ? (
                                    <Popup
                                      content="Download CSV"
                                      trigger={
                                        <Icon
                                          name="file"
                                          onClick={() =>
                                            this.handleOpen(
                                              portfolio.name,
                                              portfolio.variables
                                            )
                                          }
                                          size="large"
                                        />
                                      }
                                    />
                                  ) : null}

                                  {/* <Popup
                                    content="Download Loss"
                                    trigger={
                                      <Icon
                                        name="dollar sign"
                                        onClick={() =>
                                          this.handleLossModalOpen(
                                            portfolio.name
                                          )
                                        }
                                        size="large"
                                      />
                                    }
                                  />

                                  {tierdata &&
                                  tierdata.download_options.includes(
                                    "download_summary"
                                  ) ? (
                                    <Popup
                                      content="Download Summary"
                                      trigger={
                                        <Icon
                                          name="file alternate"
                                          onClick={() =>
                                            this.handleSummaryModalOpen(
                                              portfolio.name
                                            )
                                          }
                                          size="large"
                                        />
                                      }
                                    />
                                  ) : null}
                                </Col>
                                <Col lg={5}>
                                  <br />
                                  <Popconfirm
                                    placement="topLeft"
                                    title={
                                      <div>
                                        Are you sure you want to <br /> delete{" "}
                                        <b>{portfolio.name}</b>
                                      </div>
                                    }
                                    onConfirm={() =>
                                      this.delete(portfolio.name)
                                    }
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <Icon
                                      name="trash alternate"
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                      size="large"
                                    ></Icon>
                                  </Popconfirm>

                                  <button
                                    class="button button--primary buttons__comprar"
                                    onClick={() =>
                                      this.handleEdit(portfolio.name)
                                    }
                                  >
                                    <Icon
                                      name="edit"
                                      style={{ color: "#18202F" }}
                                      size="large"
                                    />{" "}
                                  </button>
                                  <button
                                    class="button button--primary buttons__comprar"
                                    onClick={() => this.handleShare(portfolio)}
                                  >
                                    <Icon
                                      name="share alternate"
                                      style={{ color: "#18202F" }}
                                      size="large"
                                    />
                                  </button>
                                </Col>
                              </Row>*/}
                              <Row className="justify-content-between">
                                <Col></Col>
                                <Col lg={5}>
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      fontFamily: "montserrat",
                                      marginBottom: "0em",
                                    }}
                                  >
                                    Download CSVs
                                  </p>
                                </Col>
                                <Col lg={5}>
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      fontFamily: "montserrat",
                                      marginBottom: "0em",
                                    }}
                                  >
                                    Actions
                                  </p>
                                </Col>
                                <Col></Col>
                              </Row>
                              <Row className="justify-content-between">
                                <Col></Col>
                                <Col lg={5}>
                                  <Row>
                                    <Col>
                                      {tierdata &&
                                      tierdata.download_options.includes(
                                        "download_csv"
                                      ) ? (
                                        <Popup
                                          content="Download CSV"
                                          trigger={
                                            <Icon
                                              name="file"
                                              onClick={() =>
                                                this.handleOpen(
                                                  portfolio.name,
                                                  portfolio.variables
                                                )
                                              }
                                              size="large"
                                            />
                                          }
                                        />
                                      ) : (
                                        <Popup
                                          content="This icon is disabled"
                                          trigger={
                                            <Icon
                                              name="file"
                                              size="large"
                                              disabled
                                            />
                                          }
                                        />
                                      )}
                                    </Col>
                                    <Col>
                                      {tierdata &&
                                      tierdata.download_options.includes(
                                        "download_summary"
                                      ) ? (
                                        <Popup
                                          content="Download Summary"
                                          trigger={
                                            <Icon
                                              name="file alternate"
                                              onClick={() =>
                                                this.handleSummaryModalOpen(
                                                  portfolio.name
                                                )
                                              }
                                              size="large"
                                            />
                                          }
                                        />
                                      ) : (
                                        <Popup
                                          content="This icon is disabled"
                                          trigger={
                                            <Icon
                                              name="file alternate"
                                              size="large"
                                              disabled
                                            />
                                          }
                                        />
                                      )}
                                    </Col>
                                    <Col></Col>
                                    <Col></Col>
                                    <Col></Col>
                                  </Row>
                                </Col>

                                <Col lg={5}>
                                  <Row>
                                    <Col>
                                      {" "}
                                      <Popconfirm
                                        placement="topLeft"
                                        title={
                                          <div>
                                            Are you sure you want to <br />{" "}
                                            delete <b>{portfolio.name}</b>
                                          </div>
                                        }
                                        onConfirm={() =>
                                          this.delete(portfolio.name)
                                        }
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <Icon
                                          name="trash alternate"
                                          style={{
                                            color: "red",
                                            cursor: "pointer",
                                          }}
                                          size="large"
                                        />
                                      </Popconfirm>
                                    </Col>
                                    <Col>
                                      <Popup
                                        content="Edit Portfolio"
                                        trigger={
                                          <Icon
                                            name="edit"
                                            style={{ color: "#18202F" }}
                                            size="large"
                                            onClick={() =>
                                              this.handleEdit(portfolio.name)
                                            }
                                          />
                                        }
                                      />
                                    </Col>
                                    <Col>
                                      <Popup
                                        content="Share Portfolio"
                                        trigger={
                                          <Icon
                                            name="share alternate"
                                            style={{
                                              color: "#18202F",
                                              marginLeft: "-25%",
                                            }}
                                            size="large"
                                            onClick={() =>
                                              this.handleShare(portfolio)
                                            }
                                          />
                                        }
                                      />
                                    </Col>
                                    <Col></Col>
                                    <Col></Col>
                                  </Row>
                                </Col>
                                <Col></Col>
                              </Row>
                            </Col>
                            <Col lg={1}></Col>
                          </Row>

                          {/* <Row>
                            <Col lg={9}></Col>
                            <Col lg={3}>
                              {this.state.share ? (
                                <Button
                                  onClick={() => this.handleShareSubmit()}
                                  style={{
                                    backgroundColor: "#18202F",
                                    color: "white",
                                  }}
                                >
                                  Submit
                                </Button>
                              ) : (
                                <Button
                                  style={{
                                    float: "right",
                                    backgroundColor: "transparent",
                                  }}
                                  onClick={() => this.handleShare()}
                                >
                                  <Icon
                                    name="share alternate"
                                    style={{ color: "#18202F" }}
                                    size="big"
                                    aria-label="Share"
                                  />
                                </Button>
                              )}
                            </Col>
                                </Row>*/}
                        </Card.Body>
                      </Card>
                    </Col>
                  ))
                : null}
            </Row>
            {/* <Row>
              {this.state.portfolios && this.state.portfolios.length > 0
                ? this.state.portfolios.slice(0, 3).map((portfolio, index) => (
                    <Col lg={4}>
                      <Card className="card_eg" key={index}>
                        <Card.Body>
                          <Card.Title
                            style={{ fontWeight: "bold" }}
                            className="card_title"
                          >
                            {portfolio.name ? (
                              <span>{portfolio.name.slice(10)} </span>
                            ) : null}
                            {portfolio.date ? (
                              <span
                                style={{
                                  float: "right",
                                  fontSize: "12px",
                                  fontWeight: "normal",
                                  fontFamily: "montserrat",
                                }}
                              >
                                {portfolio.date.slice(0, 10)}
                              </span>
                            ) : null}
                          </Card.Title>

                          <Row className="ringchart">
                            <Col lg={5}>
                              <Row>
                                {portfolio.assets ? (
                                  <p>
                                    Assets
                                    <br />
                                    {portfolio.assets}
                                  </p>
                                ) : null}
                              </Row>
                              <br />
                              <Row>
                                {portfolio.loss ? (
                                  <p style={{ color: "white" }}>
                                    CVaR
                                    <br /> $ {portfolio.loss.toFixed(2)} million
                                  </p>
                                ) : null}
                              </Row>
                            </Col>
                            {/*<Col lg={3}>
                              <br />
                              <br />
                              <Row>
                                <Popup
                                  content="Download CSV"
                                  trigger={
                                    <Icon
                                      name="file"
                                      onClick={() =>
                                        this.handleOpen(
                                          portfolio.name,
                                          portfolio.variables
                                        )
                                      }
                                      color="white"
                                      size="large"
                                    />
                                  }
                                />
                              </Row>
                              <br />
                              <Row>
                                <Popup
                                  content="Download Loss"
                                  trigger={
                                    <Icon
                                      name="dollar sign"
                                      onClick={() =>
                                        this.handleLossModalOpen(portfolio.name)
                                      }
                                      color="white"
                                      size="large"
                                    />
                                  }
                                />
                              </Row>
                              <br />
                              <Row>
                                <Popup
                                  content="Download Summary"
                                  trigger={
                                    <Icon
                                      name="file alternate"
                                      onClick={() =>
                                        this.handleSummaryModalOpen(
                                          portfolio.name
                                        )
                                      }
                                      size="large"
                                      color="white"
                                    />
                                  }
                                />
                              </Row>
                                </Col>
                            <Col lg={1}></Col>
                            <Col lg={6}>
                              <Row>
                                {portfolio.value ? (
                                  <p>
                                    Max Exposure <br /> $ {portfolio.value}{" "}
                                    million
                                  </p>
                                ) : null}
                              </Row>
                              <br />
                              <Row>
                                <p
                                  style={{
                                    color: "white",
                                  }}
                                >
                                  Climate Score
                                  <br />
                                </p>

                                <Progress
                                  className="circle_project"
                                  type="circle"
                                  percent={portfolio.overall}
                                  color="#18202F"
                                />
                              </Row>
                            </Col>
                          </Row>
                          <br />
                          {/*  <p>
                                <span style={{ fontWeight: "bold" }}>
                                  Climate Score
                                </span>
                                <span style={{ float: "right" }}>
                                  {portfolio.overall}%
                                </span>
                              </p>
                              <Card.Text>
                                The project has {portfolio.assets} assets with
                                Maximum Exposure of{" "}
                                <span style={{ color: "green" }}>
                                  {" "}
                                  ${portfolio.value}&nbsp;
                                </span>
                                million and a Climate Value at Risk under RCP
                                8.5 as&nbsp;
                                {portfolio.loss ? (
                                  <span style={{ color: "red" }}>
                                    ${portfolio.loss.toFixed(2)}
                                  </span>
                                ) : null}
                                &nbsp; million
                                </Card.Text>

                          <Row className="ringchartbtn">
                            <Col>
                              <Button
                                style={{
                                  backgroundColor: "#fff",
                                  color: "grey",
                                  fontSize: "14px",
                                  width: "100px",

                                  borderRadius: "5px",
                                }}
                                variant="primary"
                                onClick={() => this.handleDashboard(portfolio)}
                              >
                                Analyse
                              </Button>
                            </Col>
                            <Col>
                              {/*<Button
                                style={{
                                  backgroundColor: "#ffffff",
                                  color: "grey",
                                  fontSize: "14px",
                                  width: "100px",

                                  borderRadius: "5px",
                                }}
                                variant="primary"
                                onClick={() =>
                                  this.handleReport(portfolio.name)
                                }
                              >
                                Report
                              </Button>
                              <Dropdown
                                button
                                text="Download"
                                className="download_csv"
                              >
                                <Dropdown.Menu>
                                  <Dropdown.Item text="Report"></Dropdown.Item>
                                  <Dropdown.Item text="CSV"></Dropdown.Item>
                                  <Dropdown.Item text="Loss"></Dropdown.Item>
                                  <Dropdown.Item text="Summary"></Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </Col>
                          </Row>
                          <br />

                          {/* <Row className="card_btm_row">
                                <Col lg={6}>
                                  {this.state.share ? (
                                    <span style={{ marginTop: "0%" }}>
                                      <Dropdown
                                        placeholder="Share"
                                        fluid
                                        multiple
                                        selection
                                        options={options}
                                      />
                                    </span>
                                  ) : (
                                    <span>
                                      <p
                                        style={{
                                          fontSize: "14px",
                                          fontFamily: "montserrat",
                                        }}
                                      >
                                        Team
                                      </p>
                                      {portfolio.shared_with ? (
                                        <Row style={{ marginLeft: "2%" }}>
                                          {portfolio.shared_with.map(
                                            (share, index) => {
                                              console.log("share", share);
                                              return (
                                                <>
                                                  <Col lg={4}>
                                                    <Popup
                                                      content={share}
                                                      trigger={
                                                        <Image
                                                          src={team1}
                                                          size="mini"
                                                        />
                                                      }
                                                    />
                                                  </Col>
                                                </>
                                              );
                                            }
                                          )}
                                          <Col lg={4}>
                                            <p style={{ width: "70px" }}>
                                              +{portfolio.shared_with.length}
                                              {""} more
                                            </p>
                                          </Col>
                                        </Row>
                                      ) : null}
                                    </span>
                                  )}
                                </Col>

                                <Col lg={6}>
                                  <Row>
                                    <Col lg={6}>
                                      <p
                                        style={{
                                          fontSize: "14px",
                                          fontFamily: "montserrat",
                                        }}
                                      >
                                        Download CSVs
                                      </p>
                                      <Popup
                                        content="Download CSV"
                                        trigger={
                                          <Icon
                                            name="file"
                                            onClick={() =>
                                              this.handleOpen(
                                                portfolio.name,
                                                portfolio.variables
                                              )
                                            }
                                            size="large"
                                          />
                                        }
                                      />
                                      <Popup
                                        content="Download Loss"
                                        trigger={
                                          <Icon
                                            name="dollar sign"
                                            onClick={() =>
                                              this.handleLossModalOpen(
                                                portfolio.name
                                              )
                                            }
                                            size="large"
                                          />
                                        }
                                      />

                                      <Popup
                                        content="Download Summary"
                                        trigger={
                                          <Icon
                                            name="file alternate"
                                            onClick={() =>
                                              this.handleSummaryModalOpen(
                                                portfolio.name
                                              )
                                            }
                                            size="large"
                                          />
                                        }
                                      />
                                    </Col>
                                    <Col lg={6}>
                                      <br />
                                      <Popconfirm
                                        placement="topLeft"
                                        title={
                                          <div>
                                            Are you sure you want to <br />{" "}
                                            delete <b>{portfolio.name}</b>
                                          </div>
                                        }
                                        onConfirm={() =>
                                          this.delete(portfolio.name)
                                        }
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <Icon
                                          name="trash alternate"
                                          style={{
                                            color: "red",
                                            cursor: "pointer",
                                          }}
                                          size="large"
                                        ></Icon>
                                      </Popconfirm>

                                      <button
                                        class="button button--primary buttons__comprar"
                                        onClick={() =>
                                          this.handleEdit(portfolio.name)
                                        }
                                      >
                                        <Icon
                                          name="edit"
                                          style={{ color: "#18202F" }}
                                          size="large"
                                        />{" "}
                                      </button>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>

                              <Row>
                                <Col lg={9}></Col>
                                <Col lg={3}>
                                  {this.state.share ? (
                                    <Button
                                      onClick={() => this.handleShareSubmit()}
                                      style={{
                                        backgroundColor: "#18202F",
                                        color: "white",
                                      }}
                                    >
                                      Submit
                                    </Button>
                                  ) : (
                                    <Button
                                      style={{
                                        float: "right",
                                        backgroundColor: "transparent",
                                      }}
                                      onClick={() => this.handleShare()}
                                    >
                                      <Icon
                                        name="share alternate"
                                        style={{ color: "#18202F" }}
                                        size="big"
                                        aria-label="Share"
                                      />
                                    </Button>
                                  )}
                                </Col>
                                    </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))
                : null}
                                    </Row>*/}
          </Col>
        </Row>
        <Row>
          {/* <Col lg={12}>
            {this.state.portfolios && this.state.portfolios.length > 0 ? (
              <ProjectBarComponent data={this.state.portfolios} />
            ) : null}
            </Col>*/}
        </Row>

        <Modal
          open={this.state.project_view}
          onClose={this.handleClose}
          closeIcon
          size="fullscreen"
          style={{ overflowY: "auto" }}
        >
          <Modal.Header>All Projects</Modal.Header>
          <Modal.Content scrolling>
            <Row>
              {this.state.portfolios && this.state.portfolios.length > 0
                ? this.state.portfolios.map((portfolio, index) => (
                    <Col lg={3}>
                      <Card
                        className="card_eg"
                        key={index}
                        style={{ border: "1px solid lightgrey" }}
                      >
                        <Card.Body>
                          <Card.Title
                            className="card_title"
                            style={{
                              fontWeight: "bold",
                              backgroundColor: "#18202F",
                              color: "white",
                              padding: "2%",
                            }}
                          >
                            {portfolio.name} <br />
                            {portfolio.date ? (
                              <span
                                style={{
                                  float: "right",
                                  fontSize: "12px",
                                  fontWeight: "normal",
                                }}
                              >
                                {portfolio.date.slice(0, 10)}
                              </span>
                            ) : null}
                          </Card.Title>
                          <p>
                            <span style={{ fontWeight: "normal" }}>
                              Climate Score
                            </span>
                          </p>

                          <Progress
                            percent={portfolio.overall}
                            color="#18202F"
                          />
                          <br />

                          <Card.Text style={{ padding: "2%" }}>
                            The project has {portfolio.assets} assets with
                            Maximum Exposure of{" "}
                            <span style={{ color: "green" }}>
                              {" "}
                              ${portfolio.value}&nbsp;
                            </span>
                            million and a Climate Value at Risk under RCP 8.5
                            as&nbsp;
                            {portfolio.loss ? (
                              <span style={{ color: "red" }}>
                                ${portfolio.loss.toFixed(2)}
                              </span>
                            ) : null}
                            &nbsp;million
                          </Card.Text>

                          <Row>
                            <Col
                              lg={3}
                              style={{
                                marginLeft: "18%",
                              }}
                            >
                              <Button
                                style={{
                                  backgroundColor: "#18202F",
                                  color: "#ffffff",
                                  fontSize: "14px",
                                  width: "100px",
                                  border: "2px solid #18202F",
                                  borderRadius: "5px",
                                }}
                                variant="primary"
                                onClick={() => this.handleDashboard(portfolio)}
                              >
                                Analyse
                              </Button>
                            </Col>
                            <Col lg={3} style={{ marginLeft: "10%" }}>
                              <Button
                                style={{
                                  backgroundColor: "#ffffff",
                                  color: "#18202F",
                                  fontSize: "14px",
                                  width: "100px",
                                  border: "2px solid #18202F",
                                  borderRadius: "5px",
                                }}
                                variant="primary"
                                onClick={() => this.handleReport(portfolio)}
                              >
                                Report
                              </Button>
                            </Col>
                            {/* <Col lg={6}>  <Button style={{backgroundColor:'#FDB827', color:'white'}} variant="primary" onClick={()=>this.handleDetailedReport(portfolio)}>TCFD Report</Button></Col>*/}
                          </Row>
                          <br />

                          <Row className="card_btm_row">
                            {/* <Col lg={6}>
                          {this.state.share ? (
                            <span style={{ marginTop: "0%" }}>
                              <Dropdown
                                placeholder="Share"
                                fluid
                                multiple
                                selection
                                options={options}
                              />
                            </span>
                          ) : (
                            <span>
                              <p
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "montserrat",
                                }}
                              >
                                Team
                              </p>
                              {portfolio.shared_with ? (
                                <Row style={{ marginLeft: "2%" }}>
                                  {portfolio.shared_with.map(
                                    (share, index) => {
                                      console.log("share", share);
                                      return (
                                        <>
                                          <Col lg={4}>
                                            <Popup
                                              content={share}
                                              trigger={
                                                <Image
                                                  src={team1}
                                                  size="mini"
                                                />
                                              }
                                            />
                                          </Col>
                                        </>
                                      );
                                    }
                                  )}
                                  <Col lg={4}>
                                    <p style={{ width: "70px" }}>
                                      +{portfolio.shared_with.length}
                                      {""} more
                                    </p>
                                  </Col>
                                </Row>
                              ) : null}
                            </span>
                          )}
                                  </Col>*/}
                            <Col lg={1}></Col>
                            <Col lg={11}>
                              <Row>
                                <Col lg={8}>
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      fontFamily: "montserrat",
                                    }}
                                  >
                                    Download CSVs
                                  </p>
                                  <Popup
                                    content="Download CSV"
                                    trigger={
                                      <Icon
                                        name="file"
                                        onClick={() =>
                                          this.handleOpen(
                                            portfolio.name,
                                            portfolio.variables
                                          )
                                        }
                                        size="large"
                                      />
                                    }
                                  />
                                  {/*  <Popup
                                    content="Download Loss"
                                    trigger={
                                      <Icon
                                        name="dollar sign"
                                        onClick={() =>
                                          this.handleLossModalOpen(
                                            portfolio.name
                                          )
                                        }
                                        size="large"
                                      />
                                    }
                                  />*/}

                                  <Popup
                                    content="Download Summary"
                                    trigger={
                                      <Icon
                                        name="file alternate"
                                        onClick={() =>
                                          this.handleSummaryModalOpen(
                                            portfolio.name
                                          )
                                        }
                                        size="large"
                                      />
                                    }
                                  />
                                </Col>
                                <Col lg={4}>
                                  <br />
                                  <Popconfirm
                                    placement="topLeft"
                                    title={
                                      <div>
                                        Are you sure you want to <br /> delete{" "}
                                        <b>{portfolio.name}</b>
                                      </div>
                                    }
                                    onConfirm={() =>
                                      this.delete(portfolio.name)
                                    }
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <Icon
                                      name="trash alternate"
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                      size="large"
                                    ></Icon>
                                  </Popconfirm>

                                  <button
                                    class="button button--primary buttons__comprar"
                                    onClick={() =>
                                      this.handleEdit(portfolio.name)
                                    }
                                  >
                                    <Icon
                                      name="edit"
                                      style={{ color: "#18202F" }}
                                      size="large"
                                    />{" "}
                                  </button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          {/* <Row>
                        <Col lg={9}></Col>
                        <Col lg={3}>
                          {this.state.share ? (
                            <Button
                              onClick={() => this.handleShareSubmit()}
                              style={{
                                backgroundColor: "#18202F",
                                color: "white",
                              }}
                            >
                              Submit
                            </Button>
                          ) : (
                            <Button
                              style={{
                                float: "right",
                                backgroundColor: "transparent",
                              }}
                              onClick={() => this.handleShare()}
                            >
                              <Icon
                                name="share alternate"
                                style={{ color: "#18202F" }}
                                size="big"
                                aria-label="Share"
                              />
                            </Button>
                          )}
                        </Col>
                            </Row>*/}
                        </Card.Body>
                      </Card>
                    </Col>
                  ))
                : null}
            </Row>
          </Modal.Content>
        </Modal>

        <Modal
          open={this.state.modalEdit}
          onClose={this.handleClose}
          closeIcon
          itemName={this.state.activeItemName}
        >
          <Modal.Header>Update Project</Modal.Header>
          <Modal.Content scrolling>
            <div style={{ marginLeft: "20%", marginRight: "20%" }}>
              <Grid.Row>
                <Form.Field
                  control={Input}
                  placeholder={this.state.activeItemName}
                  value={this.state.name}
                  disabled
                  style={{ width: "100%" }}
                />
              </Grid.Row>
              <br />
              {/*<Grid.Row>
                <Dropdown
                  placeholder="Status"
                  fluid
                  selection
                  options={StatusOptions}
                  value={status}
                  onChange={(e, { value }) =>
                    this.handleChange(value, "status")
                  }
                />
              </Grid.Row>
              <br />
              <Grid.Row>
                <Form.Field
                  control={Input}
                  placeholder="description"
                  value={this.state.description}
                  onChange={(e) =>
                    this.setState({ description: e.target.value })
                  }
                />
                </Grid.Row>*/}

              <Grid.Row>
                <Form.Field
                  control={Input}
                  placeholder="New Project Name"
                  value={this.state.new_project}
                  onChange={(e) =>
                    this.setState({ new_project: e.target.value })
                  }
                />
              </Grid.Row>
              <br />

              {this.state.loading && this.props.errors.error == undefined ? (
                <Button
                  style={{
                    backgroundColor: "#18202F",
                    marginLeft: "45%",
                  }}
                >
                  <Spinner />
                </Button>
              ) : (
                <Button
                  style={{
                    backgroundColor: "#18202F",
                    color: "white",
                    marginLeft: "45%",
                  }}
                  onClick={this.onSubmit}
                  primary
                >
                  Submit
                </Button>
              )}
            </div>
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.filterOpen}
          closeIcon
          onClose={this.handleClose}
        >
          <Modal.Header className="advance">
            <h2>User Default Options</h2>
            <p>Change User Default Options for more insights</p>
          </Modal.Header>
          <Modal.Content className="Filter">
            <Row>
              <Col lg={4}>
                <p>Analysis Type</p>
                <Form.Select
                  placeholder="Analysis Type"
                  onChange={(e, { value }) =>
                    this.handleChange(value, "scenario")
                  }
                  value={this.state.scenario}
                  options={ScenarioOptions}
                />
              </Col>

              <Col lg={4}>
                {this.state.scenario == "rcp" ? (
                  <>
                    {" "}
                    <p>RCP</p>
                    <Form.Select
                      placeholder="RCP"
                      onChange={(e, { value }) =>
                        this.handleChange(value, "rcp")
                      }
                      value={this.state.rcp}
                      options={rcpOptions}
                    />
                  </>
                ) : (
                  <>
                    <p>SSP</p>
                    <Form.Select
                      placeholder="SSP"
                      onChange={(e, { value }) =>
                        this.handleChange(value, "rcp")
                      }
                      value={this.state.rcp}
                      options={rcpOptions}
                    />
                  </>
                )}
              </Col>

              <Col lg={4}>
                <p>Year</p>
                {this.state.scenario == "rcp" ? (
                  <Form.Select
                    placeholder="Year"
                    onChange={(e, { value }) =>
                      this.handleChange(value, "year")
                    }
                    value={this.state.year}
                    options={yearOptions_default}
                  />
                ) : (
                  <Form.Select
                    placeholder="Year"
                    onChange={(e, { value }) =>
                      this.handleChange(value, "year")
                    }
                    value={this.state.year}
                    options={sspyearOptions_default}
                  />
                )}
              </Col>
            </Row>

            <br />

            <br />

            <Button
              onClick={this.handleUpdateFilter}
              style={{
                backgroundColor: "#18202F",
                color: "white",
                float: "right",
              }}
            >
              Apply Filter
            </Button>
            <br />
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.modalShare}
          onClose={this.handleClose}
          closeIcon
          itemName={this.state.activeItemName}
        >
          <Modal.Header>Share Project</Modal.Header>
          <Modal.Content scrolling>
            <div style={{ marginLeft: "20%", marginRight: "20%" }}>
              <Grid.Row>
                <p>Project Name</p>
                <Form.Field
                  control={Input}
                  placeholder={this.state.activeItemName}
                  value={this.state.name}
                  style={{ width: "100%" }}
                />
              </Grid.Row>
              <br />
              {/*<Grid.Row>
                <Dropdown
                  placeholder="Status"
                  fluid
                  selection
                  options={StatusOptions}
                  value={status}
                  onChange={(e, { value }) =>
                    this.handleChange(value, "status")
                  }
                />
              </Grid.Row>
              <br />
              <Grid.Row>
                <Form.Field
                  control={Input}
                  placeholder="description"
                  value={this.state.description}
                  onChange={(e) =>
                    this.setState({ description: e.target.value })
                  }
                />
                </Grid.Row>*/}

              <Grid.Row>
                <p>Select Users to Share Project with</p>
                <Dropdown
                  placeholder="Select Users "
                  fluid
                  selection
                  multiple
                  options={userOptions}
                  value={this.state.user}
                  onChange={(e, { value }) => this.handleChange(value, "user")}
                />
              </Grid.Row>
              <br />
              <Grid.Row>
                <p>Permissions </p>
                <Dropdown
                  placeholder="Permissions"
                  fluid
                  selection
                  options={permissionOptions}
                  value={this.state.permission}
                  onChange={(e, { value }) =>
                    this.handleChange(value, "permission")
                  }
                />
              </Grid.Row>

              {this.state.loading && this.props.errors.error == undefined ? (
                <Button
                  style={{
                    backgroundColor: "#18202F",
                    marginLeft: "45%",
                  }}
                >
                  <Spinner />
                </Button>
              ) : (
                <Button
                  style={{
                    backgroundColor: "#18202F",
                    color: "white",
                    marginLeft: "45%",
                  }}
                  onClick={this.onShareSubmit}
                  primary
                >
                  Submit
                </Button>
              )}
            </div>
          </Modal.Content>
        </Modal>

        <Modal
          open={this.state.reportmodal}
          onClose={this.handleClose}
          closeIcon
        >
          <Modal.Header>Download Report</Modal.Header>
          <Modal.Content>
            <div>
              <Form.Input value={this.state.activeItemName} disabled />
              <br />
              <p>Analysis Type</p>
              <Dropdown
                placeholder="Analysis Type"
                fluid
                selection
                options={ScenarioOptions}
                value={this.state.scenario}
                onChange={(e, { value }) =>
                  this.handleChange(value, "scenario")
                }
              />

              <br />

              {/*<p style={{ whiteSpace: "nowrap" }}>
                {" "}
                <Checkbox
                  toggle
                  onClick={() => this.useValuation()}
                  checked={this.state.use_valuation}
                  label="Use Valuation"
                />
                &nbsp; Use Valuation
              </p>

              <p>Currency</p>
              <Dropdown
                placeholder="Currency"
                fluid
                selection
                options={currencyOptions}
                value={currency}
                onChange={(e, { value }) =>
                  this.handleChange(value, "currency")
                }
              />*/}
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />

              {!this.state.loading ? (
                <Button
                  onClick={this.handlePptReport}
                  style={{
                    backgroundColor: "#18202F",
                    color: "white",
                    float: "right",
                  }}
                >
                  Submit
                </Button>
              ) : (
                <Button
                  style={{
                    backgroundColor: "#18202F",
                    float: "right",
                    color: "white",
                  }}
                  disabled
                >
                  Loading
                </Button>
              )}

              {this.props.ppt.url && this.state.loading ? (
                <a href={this.props.ppt.url} target="_blank">
                  <Button
                    style={{ backgroundColor: "#18202F", color: "white" }}
                  >
                    Download
                  </Button>
                </a>
              ) : null}
              <br />
              <br />
            </div>
          </Modal.Content>
        </Modal>

        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          closeIcon
          //size="fullscreen"
          itemName={this.state.activeItemName}
        >
          <Modal.Header>
            Download Detailed Analysis Results in Excel File
          </Modal.Header>
          <Modal.Content>
            <div
              className="project_csv"
              style={{ paddingLeft: "5%", paddingRight: "5%" }}
            >
              {this.props.csv.error && this.props.csv.error ? (
                <Row>
                  <p style={{ color: "red" }}>{this.props.csv.error}</p>
                </Row>
              ) : null}
              <Row>
                <p>Select Scenario</p>

                <Dropdown
                  placeholder="Scenario"
                  fluid
                  selection
                  options={ScenarioOptions}
                  value={this.state.csv_scenario}
                  onChange={(e, { value }) =>
                    this.handleChange(value, "csv_scenario")
                  }
                />
              </Row>
              <Row>
                <p>Select Year</p>

                {this.state.csv_scenario == "rcp" ? (
                  <Dropdown
                    placeholder="Year"
                    fluid
                    multiple
                    selection
                    options={yearOptions}
                    value={csv_year}
                    onChange={(e, { value }) =>
                      this.handleChange(value, "csv_year")
                    }
                  />
                ) : (
                  <Dropdown
                    placeholder="Year"
                    fluid
                    multiple
                    selection
                    options={sspyearOptions}
                    value={csv_year}
                    onChange={(e, { value }) =>
                      this.handleChange(value, "csv_year")
                    }
                  />
                )}
              </Row>
              {this.state.csv_year.includes("2020") ? (
                <Row>
                  {this.state.csv_scenario == "rcp" ? (
                    <>
                      <p>Select RCP</p>

                      <Dropdown
                        placeholder="RCP"
                        fluid
                        multiple
                        selection
                        options={rcp_2020_Options}
                        value={csv_rcp}
                        onChange={(e, { value }) =>
                          this.handleChange(value, "csv_rcp")
                        }
                      />
                    </>
                  ) : (
                    <>
                      <p>Select SSP</p>

                      <Dropdown
                        placeholder="SSP"
                        fluid
                        multiple
                        selection
                        options={rcp_2020_Options}
                        value={csv_rcp}
                        onChange={(e, { value }) =>
                          this.handleChange(value, "csv_rcp")
                        }
                      />
                    </>
                  )}
                </Row>
              ) : (
                <Row>
                  {this.state.csv_scenario == "rcp" ? (
                    <>
                      <p>Select RCP</p>

                      <Dropdown
                        placeholder="RCP"
                        fluid
                        multiple
                        selection
                        options={rcpOptions}
                        value={csv_rcp}
                        onChange={(e, { value }) =>
                          this.handleChange(value, "csv_rcp")
                        }
                      />
                    </>
                  ) : (
                    <>
                      <p>Select SSP</p>

                      <Dropdown
                        placeholder="SSP"
                        fluid
                        multiple
                        selection
                        options={rcpOptions}
                        value={csv_rcp}
                        onChange={(e, { value }) =>
                          this.handleChange(value, "csv_rcp")
                        }
                      />
                    </>
                  )}
                </Row>
              )}

              <Row>
                <p>Select Climate Variable</p>

                <Dropdown
                  placeholder="Climate variables"
                  fluid
                  multiple
                  selection
                  options={variableOptions}
                  value={variables}
                  onChange={(e, { value }) =>
                    this.handleChange(value, "variables")
                  }
                />
              </Row>

              <Row>
                <p>Select Portfolio Losses</p>

                <Dropdown
                  placeholder="Portfolio Losses"
                  fluid
                  multiple
                  selection
                  options={PortfolioLossOptions}
                  value={portfolio_loss}
                  onChange={(e, { value }) =>
                    this.handleChange(value, "portfolio_loss")
                  }
                />
              </Row>

              <Row>
                <p>Mitigation Measures</p>

                <Dropdown
                  placeholder="Mitigation Measures"
                  fluid
                  selection
                  options={MetigationOptions}
                  value={this.state.metigation}
                  onChange={(e, { value }) =>
                    this.handleChange(value, "metigation")
                  }
                />
              </Row>
              <Row>
                <p>Select Content</p>
                <Dropdown
                  placeholder="Content"
                  fluid
                  selection
                  options={contentOptions}
                  value={this.state.content}
                  onChange={(e, { value }) =>
                    this.handleChange(value, "content")
                  }
                />
              </Row>

              <Row>
                <p>Select Currency</p>
                <Dropdown
                  placeholder="Currency"
                  fluid
                  selection
                  options={currencyOptions}
                  value={currency}
                  onChange={(e, { value }) =>
                    this.handleChange(value, "currency")
                  }
                />
              </Row>
              <Row>
                <p>
                  Account for Long-term Impact of Climate Change on Economic
                  Growth
                </p>
                <Dropdown
                  placeholder="Adjusting Loss"
                  fluid
                  selection
                  options={gdpOptions}
                  value={gdp}
                  onChange={(e, { value }) => this.handleChange(value, "gdp")}
                />
              </Row>
              {/*<Row>
                <p>Technical Insurance Premium in %</p>
                <Dropdown
                  placeholder="Valuation"
                  fluid
                  selection
                  options={valuationOptions}
                  value={valuation}
                  onChange={(e, { value }) =>
                    this.handleChange(value, "valuation")
                  }
                />
                </Row>*/}
              {/* <Row>
                <p>Agriculture Variables</p>
                <Dropdown
                  placeholder="Agriculture Variables"
                  fluid
                  multiple
                  selection
                  options={agriOptions}
                  value={this.state.agri}
                  onChange={(e, { value }) => this.handleChange(value, "agri")}
                />
              </Row>
              <Row>
                <p>Energy Variables</p>
                <Dropdown
                  placeholder="Energy Variables"
                  fluid
                  multiple
                  selection
                  options={energyOptions}
                  value={this.state.energy}
                  onChange={(e, { value }) =>
                    this.handleChange(value, "energy")
                  }
                />
                </Row>*/}

              <br />
              <Button
                style={{
                  backgroundColor: "#18202F",
                  color: "white",
                  marginLeft: "45%",
                }}
                onClick={this.handleSubmit}
                primary
              >
                Submit
              </Button>

              {this.props.csv.url ? (
                <a href={this.props.csv.url} target="_blank">
                  <Button
                    style={{
                      backgroundColor: "#18202F",
                      color: "white",
                      marginLeft: "40%",
                    }}
                  >
                    Download
                  </Button>
                </a>
              ) : null}
            </div>
          </Modal.Content>
        </Modal>

        <Modal
          open={this.state.lossmodalOpen}
          onClose={this.handleLossModalClose}
          closeIcon
          itemName={this.state.activeItemName}
        >
          <Modal.Header>Download Loss</Modal.Header>
          <Modal.Content scrolling>
            <div style={{ marginLeft: "20%", marginRight: "20%" }}>
              <p>Select Year</p>
              <Grid.Row>
                <Dropdown
                  placeholder="Year"
                  fluid
                  selection
                  options={yearOptions}
                  value={year}
                  onChange={(e, { value }) => this.handleChange(value, "year")}
                />
              </Grid.Row>
              <br />

              {this.state.modalloading &&
              !this.props.csv.length === undefined ? (
                <Button
                  style={{
                    backgroundColor: "#18202F",
                    marginLeft: "45%",
                  }}
                >
                  <Spinner />
                </Button>
              ) : (
                <Button
                  style={{
                    backgroundColor: "#18202F",
                    color: "white",
                    marginLeft: "45%",
                  }}
                  onClick={this.handleLossSubmit}
                  primary
                >
                  Submit
                </Button>
              )}

              {this.props.csv.length === undefined ? (
                <CsvDownloadButton
                  data={this.props.csv.success}
                  style={{
                    backgroundColor: "#18202F",
                    color: "white",
                    border: "0px solid white",
                    padding: "10px",
                    float: "right",
                    borderRadius: "5%",
                    fontWeight: "bold",
                  }}
                />
              ) : null}
            </div>
          </Modal.Content>
        </Modal>

        <Modal
          open={this.state.summarymodalOpen}
          onClose={this.handleSummaryModalClose}
          closeIcon
          itemName={this.state.activeItemName}
        >
          <Modal.Header>Download Summary</Modal.Header>
          <Modal.Content scrolling>
            <div style={{ marginLeft: "20%", marginRight: "20%" }}>
              <Row>
                <Form.Field
                  id="form-input-control-project"
                  control={Input}
                  label="Portfolio"
                  placeholder={this.state.activeItemName}
                  disabled
                  style={{ width: "100%" }}
                />
              </Row>
              <p>Select Scenario</p>
              <Row>
                <Dropdown
                  placeholder="Scenario"
                  fluid
                  selection
                  options={ScenarioOptions}
                  value={this.state.scenario}
                  onChange={(e, { value }) =>
                    this.handleChange(value, "scenario")
                  }
                />
              </Row>
              <br />
              <Row>
                <p>Mitigation Measures</p>

                <Dropdown
                  placeholder="Mitigation Measures"
                  fluid
                  selection
                  options={MetigationOptions}
                  value={this.state.metigation}
                  onChange={(e, { value }) =>
                    this.handleChange(value, "metigation")
                  }
                />
              </Row>
              <br />

              {this.state.modalloading &&
              !this.props.csv.length === undefined ? (
                <Button
                  style={{
                    backgroundColor: "#fdb827",
                    marginLeft: "45%",
                  }}
                >
                  <Spinner />
                </Button>
              ) : (
                <Button
                  style={{
                    backgroundColor: "#18202F",
                    color: "white",
                    marginLeft: "40%",
                  }}
                  onClick={this.handleSummarySubmit}
                  primary
                >
                  Submit
                </Button>
              )}

              {this.props.csv.url ? (
                <a href={this.props.csv.url} target="_blank">
                  <Button
                    style={{
                      backgroundColor: "#18202F",
                      color: "white",
                      marginLeft: "40%",
                    }}
                  >
                    Download
                  </Button>
                </a>
              ) : null}
            </div>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.project.errors,
    locus: state.locus.locus,
    csv: state.project.csv,
    project: state.project.project,
    ppt: state.project.ppt,
    map: state.project.map,
    overall_map: state.project.overall_map,
    link: state.project.link,
    list_physical: state.project.list,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLocations: () => {
      dispatch(locus.getLocations());
    },
    logout: () => {
      dispatch(auth.logout());
    },
    addProject: (formdata) => {
      dispatch(project.addProject(formdata));
    },
    getCSV: (formdata) => {
      dispatch(project.getCSV(formdata));
    },
    getLoss: (formdata) => {
      dispatch(project.getLoss(formdata));
    },
    getSummary: (formdata) => {
      dispatch(project.getSummary(formdata));
    },
    changeTitle: (title) => {
      dispatch(changeTitle(title));
    },
    getProjects: () => {
      dispatch(project.getProjects());
    },
    deleteProject: (formdata) => {
      dispatch(project.deleteProject(formdata));
    },
    getPptReport: (formdata) => {
      dispatch(project.getPptReport(formdata));
    },
    getSatelliteMap: (formdata) => {
      dispatch(project.getSatelliteMap(formdata));
    },
    getMap: (formdata) => {
      dispatch(project.getMap(formdata));
    },
    Dropbox: (formdata) => {
      dispatch(project.Dropbox(formdata));
    },
    getProjectList: (physical_risk) => {
      dispatch(project.getProjectList(physical_risk));
    },
    shareProject: (formdata) => {
      dispatch(project.shareProject(formdata));
    },
    updateFilter: (formdata) => {
      dispatch(project.updateFilter(formdata));
    },
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PhysicalProject)
);
